
<template>
  <div>
    <Hero class="mb-10"></Hero>
    <Content ></Content>
  </div>
</template>

<script>

//@ts-check
import hero from "../components/hero.vue"
import content from "../components/content.vue"
export default {
  components:{
    Hero:hero,
    Content:content
  },
  data() {
    return {
    };
  },
};
</script>

