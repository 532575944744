<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex bluemain">
        <h4 class="white--text">รูปแบบใบเสร็จรับเงิน / Receipt Format</h4>
      </v-card-title>
      <v-card-text class="pa-7">
        <h3
          v-if="billingInfo.billingOption == 0"
          class="black--text "
        >
          ในนามบุคคล (ผู้ถือกรมธรรม์) / Detail as a Insured (Policyholder)
        </h3>
        <div v-else>
          <h3 class="black--text font-weight-regular">
            ในนามบริษัท / Detail as a Company
          </h3>

          <div class="pt-5">
            <v-row no-gutters>
              <v-col cols="3">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="font-weight-regular black--text">ชื่อบริษัท</h3>
                    <h4 class="font-weight-regular">Name of Company</h4>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="9">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="black--text">{{ billingInfo.comName }}</h3>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="font-weight-regular black--text">สาขา</h3>
                    <h4 class="font-weight-regular">Branch</h4>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="black--text">{{ billingInfo.comBranch }}</h3>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="font-weight-regular black--text">
                      เลขประจำตัวผู้เสียภาษี
                    </h3>
                    <h4 class="font-weight-regular">Taxpayer ID Number</h4>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="3">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="black--text">{{ billingInfo.vatNumber }}</h3>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="3">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="font-weight-regular black--text">ที่อยู่</h3>
                    <h4 class="font-weight-regular">Address</h4>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="9">
                <v-card tile outlined>
                  <v-card-text>
                    <h3 class="black--text">
                      {{ billingInfo.comAddress }}
                      {{ billingInfo.comAddressObject.tambonEngShort }}
                      {{ billingInfo.comAddressObject.districtEngShort }}
                      {{ billingInfo.comAddressObject.provinceEng }}
                      {{ billingInfo.comAddressObject.postCodeMain }}
                    </h3>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      billingInfo: this.$store.getters.billingInfo,
    };
  },
};
</script>

<style scoped>
.v-sheet >>> .v-card {
  height: 100%;
}
</style>