<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title class="d-flex bluemain px-8">
            <h3 class="white--text">ชำระเงินสมบูรณ์ / Payment Completed</h3>
          </v-card-title>
          <v-card-text class="px-8">
            <v-row justify="center" class="my-10">
              <v-col cols="10">
                <div class="mb-5 textdescription">
                  <h2 class="text-center bluemain--text">
                    บริษัท ไทยประกันสุขภาพ จำกัด (มหาชน)
                    ขอขอบพระคุณที่ท่านซื้อผลิตภัณฑ์ประกันวินาศภัยของเรา
                  </h2>
                  <h2 class="text-center bluemain--text">
                    บริษัทจะส่งเอกสารกรมธรรม์ของท่านไปยังอีเมล
                    หรือที่อยู่ของท่าน ตามที่ระบุไว้
                  </h2>
                  <h2 class="text-center red--text font-weight-medium">
                    (หากท่านไม่พบอีเมล ในกล่องข้อความ โปรดตรวจสอบอีกครั้งใน
                    junk mail)
                  </h2>
                  <h3 class="text-center py-4 gray--text font-weight-medium">
                    *ในกรณีที่ท่านได้รับของกำนัลพิเศษ หรือรางวัลโปรโมชั่น
                    ที่ตรงตามเงื่อนไข ทางบริษัทฯ
                    จะจัดส่งของกำนัลไปยังที่อยู่ที่ท่านระบุไว้
                    <span class="text-decoration-underline"
                      >ภายใน 45 วันทำการ</span
                    >
                    หลังจากวันที่สมัครทำประกันการเดินทาง
                  </h3>
                </div>

                <div class="textdescription">
                  <h2 class="text-center bluemain--text">
                    Thai Health Insurance PLC., we thank you for your purchasing
                    our insurance products.
                  </h2>
                  <h2 class="text-center bluemain--text">
                    We will send your policy to email or your address according
                    to you specified.
                  </h2>
                  <h2 class="text-center red--text font-weight-medium">
                    (In case of non receive email in Inbox folder, please check
                    your junk mail folder.)
                  </h2>
                  <h3 class="text-center py-4 gray--text font-weight-medium">
                    *We will send the gift to your address (in Thailand only
                    within 45 working days from your date of purchase)
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="auto">
                <v-btn large>
                  <router-link to="/">
                    <h2 class="bluemain--text">ย้อนกลับ / Back</h2>
                  </router-link>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped>
.textdescription h2,
h3 {
  line-height: 1.5;
}
</style>