<template>
  <v-container>
    <v-btn block x-large @click="postPayment" height="4.5rem">
      <h3>
        ยืนยันข้อมูลและชำระเงิน <br />
        Confirm and Payment
      </h3>
    </v-btn>

    <v-dialog v-model="errMsg" width="400" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center red">
          <h2 class="white--text">Error</h2>

          <v-icon class="white--text pa-1"> warning </v-icon>
        </v-card-title>
        <v-card-text class="mt-3">
          <h3 class="text-center font-weight-regular">พบข้อผิดพลาด</h3>
          <h3 class="text-center font-weight-regular">กรุณาติดต่อ support</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn block x-large text @click="backToMainPage">
            <h2>กลับสู่หน้าหลัก</h2>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="draftCheck" width="400" persistent>
      <v-card>
        <v-card-title class="d-flex justify-center orange">
          <h2 class="white--text">Sorry</h2>

          <v-icon class="white--text pa-1">
            sentiment_very_dissatisfied
          </v-icon>
        </v-card-title>
        <v-card-text class="mt-3">
          <h3 class="text-center font-weight-regular">ท่านได้ชำระเงินไปแล้ว</h3>
          <h3 class="text-center font-weight-regular">
            หากมีข้อสงสัยกรุณาติดต่อ support
          </h3>
        </v-card-text>
        <v-card-actions>
          <v-btn block x-large text @click="backToMainPage">
            <h2>กลับสู่หน้าหลัก</h2>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <form id="myform" method="post" :action="payment_url" v-show="false">
      <input
        type="hidden"
        name="version"
        v-model="payment_package.version"
        lazy
      />
      <input
        type="hidden"
        name="merchant_id"
        v-model="payment_package.merchant_id"
        lazy
      />
      <input
        type="hidden"
        name="currency"
        v-model="payment_package.currency"
        lazy
      />
      <input
        type="hidden"
        name="hash_value"
        v-model="payment_package.hash_value"
        lazy
      />
      <input
        type="hidden"
        name="result_url_1"
        v-model="payment_package.result_url_1"
        lazy
      />
      <input
        type="hidden"
        name="result_url_2"
        v-model="payment_package.result_url_2"
        lazy
      />
      <input
        type="hidden"
        name="user_defined_1"
        v-model="payment_package.user_defined_1"
        lazy
      />
      <input
        type="hidden"
        name="payment_description"
        v-model="payment_package.payment_description"
        readonly
      />
      <input
        type="hidden"
        name="order_id"
        v-model="payment_package.order_id"
        readonly
        lazy
      />
      <input
        type="hidden"
        name="amount"
        v-model="payment_package.amount"
        readonly
        lazy
      />
    </form>
  </v-container>
</template>

<script>
import axios from "axios";
import router from "@/router";
export default {
  data() {
    return {
      errMsg: false,
      draftCheck: false,
      payment_url: "",
      payment_package: {},
    };
  },
  created: async function () {
    await axios
      .get(`/payment/2c2pdirectpayment/${this.$route.params.refid}`)
      .then((res) => {
        this.$store.commit("setCustomerData", res.data.confirmation_data);
        switch (res.data.status) {
          case "ACTUAL":
            this.draftCheck = true;
            break;
          case "DRAFT":
            this.payment_package = res.data.payment_package;
            this.payment_url = res.data.payment_url;
            break;
          default:
            this.errMsg = true;
            break;
        }
      })
      .catch(() => {
        this.errMsg = true;
      });
  },
  methods: {
    postPayment() {
      document.forms.myform.submit();
    },

    backToMainPage() {
      router.push({ name: "Home" });
    },
  },
};
</script>

<style>
</style>