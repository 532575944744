<template>
  <div>
      <NotFound></NotFound>
  </div>
</template>

<script>
import notfound from "../components/notfound.vue"
export default {
    components: {
        NotFound : notfound
    }
}
</script>

<style>

</style>