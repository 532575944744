<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title class="d-flex bluemain px-8">
            <h3 class="white--text">
              ผู้ขอเอาประกันภัยประสงค์จะเลือกรับกรมธรรม์ประกันภัยผ่านช่องทางใด / Channel for receive the insurance policy.
            </h3>
          </v-card-title>
          <v-card-text class="px-8">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-radio-group v-model="policyRevOption" mandatory>
                      <v-radio
                        label="รับเป็น e-policy ทางอีเมล (Email) ที่ระบุไว้ / Receive the e-policy by the specified email address."
                        :value="0"
                        class="mb-4"
                      ></v-radio>
                      <v-radio
                        label="รับเป็นเอกสาร โดยส่งให้ทางไปรษณีย์ ตามที่อยู่ที่ระบุไว้ / Receive the document by post at the specified address."
                        :value="1"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapFields } from "vuex-map-fields";

export default {
  data() {
    return {
      test: 1,
    };
  },

  computed: {
    ...mapFields(["policyRevOption"]),
  },
};
</script>

<style>
</style>