<template>
  <v-app>
    <Header ></Header>
    <v-main class="mt-5">
      
        <router-view></router-view>
     
    </v-main>
    <Footer class="mt-16"></Footer>
  </v-app>
</template> 

<script>
import header from "./components/header.vue";
import footer from "./components/footer.vue";


export default {
  components: {
    Header: header,
    Footer: footer,
  },

  data: () => ({
  }),

 
};
</script>

<style >
@import url('./assets/font/css/noto-sans-thai-ui.css');
@import url('./assets/font/css/noto-sans-ui.css');

*{
  font-family: 'Noto Sans Thai UI' , 'Noto Sans UI', Sans-serif ; 
  font-stretch: normal;
}

p{
  font-size: 16px ;
}

a{
  text-decoration: none;
}
</style>
