<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex bluemain">
        <h4 class="white--text">ข้อมูลการเดินทาง / Travel Information</h4>
      </v-card-title>
      <v-row no-gutters>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">ประเทศต้นทาง</h3>
              <h4 class="font-weight-regular">Departure Country</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>
                {{ travelInfo.startCountry }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">ประเทศปลายทาง</h3>
              <h4 class="font-weight-regular">Destination Country</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>
                {{ travelInfo.desCountry }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">
                วันที่เดินทางออกจากประเทศไทย
              </h3>
              <h4 class="font-weight-regular">Departure date from Thailand</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>
                {{ travelInfo.departDate }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">
                วันที่เดินทางกลับถึงประเทศไทย
              </h3>
              <h4 class="font-weight-regular">Arrival date to Thailand</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>
                {{ travelInfo.returnDate }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          <v-card outlined tile class="rounded-bl">
            <v-card-text>
              <h3 class="font-weight-regular black--text">
                จำนวนวันที่เดินทาง
              </h3>
              <h4 class="font-weight-regular">Number of Day(s)</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card outlined tile class="rounded-br">
            <v-card-text class="black--text">
              <h3>
                {{ travelInfo.numberofDay }}
                วัน
                <br />
                Day(s)
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      travelInfo: this.$store.getters.getTravelInfo,
    };
  },
};
</script>

<style scoped>
.v-sheet >>> .v-card {
  height: 100%;
}
</style>