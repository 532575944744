<template>
  <div>
    <Stepper :value="2" class="mb-10"></Stepper>
    <v-form v-model="form" ref="form" id="holderinfo">
      <!-- prop for disable birthdate -->
      <HolderInfo :disable="false"></HolderInfo>
      <BeneficiaryInfo></BeneficiaryInfo>
      <Billinginfo></Billinginfo>
      <PolicyInfo></PolicyInfo>
    </v-form>

    <v-container>
      <v-row justify="center">
        <v-col cols="12">
          <v-row justify="space-between">
            <v-col cols="auto">
              <v-btn block x-large color="white" elevation="3" @click="back()">
                <h4 class="bluemain--text">ย้อนกลับ / Back</h4>
              </v-btn>
            </v-col>
            <v-col cols="auto">
              <v-btn block x-large color="white" elevation="3" @click="next()">
                <h4 class="bluemain--text">ดำเนินการต่อ / Continue</h4>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="validateMsg" width="400">
      <v-card>
        <v-card-title class="d-flex justify-center red">
          <h2 class="white--text">Attention!</h2>
        </v-card-title>
        <v-card-text class="mt-3">
          <h3 class="text-center font-weight-regular">
            กรุณาตรวจสอบฟอร์มการสมัครของท่าน
          </h3>
          <h3 class="text-center font-weight-regular">
            ข้อมูลที่ท่านกรอกอาจผิดพลาดหรือไม่ครบถ้วน
          </h3>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import stepper from "../components/stepper.vue";
import holderinfo from "../components/holderinfo.vue";
import beneficiaryinfo from "../components/beneficiaryinfo.vue";
import billinginfo from "../components/billinginfo.vue";
import policyinfo from "../components/policyinfo.vue";
import router from "../router";

export default {
  components: {
    Stepper: stepper,
    HolderInfo: holderinfo,
    BeneficiaryInfo: beneficiaryinfo,
    Billinginfo: billinginfo,
    PolicyInfo: policyinfo,
  },

  data() {
    return {
      form: true,
      validateMsg: false,
      holderCard: document.getElementById("holderinfo"),
    };
  },

  methods: {
    back() {
      window.scrollTo(0, 0);
      router.push({ name: "Plan" });
    },

    next() {
      if (this.$refs.form.validate()) {
        window.scrollTo(0, 0);
        router.push({ name: "Verify" });
      } else {
        this.validateMsg = true;
      }
    },
  },

};
</script>

<style>
</style>