<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex bluemain">
        <h4 class="white--text">
          ผู้ขอเอาประกันภัยประสงค์จะเลือกรับกรมธรรม์ประกันภัยผ่านช่องทางใด /
          Channel for receive the insurance policy.
        </h4>
      </v-card-title>
      <v-card-text class="pa-7">
        <h3 v-if="getPolicyRev == 0" class="black--text">
          รับเป็น e-policy ทางอีเมล (Email) ที่ระบุไว้ / Receive the e-policy by
          the specified email address.
        </h3>
        <h3 v-else class="black--text">
          รับเป็นเอกสาร โดยส่งให้ทางไปรษณีย์ ตามที่อยู่ที่ระบุไว้ / Receive the
          document by post at the specified address.
        </h3>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  computed: {
    getPolicyRev() {
      return this.$store.getters.policyRevOption;
    },
  },
};
</script>

<style>
</style>