<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title class="d-flex bluemain px-8">
            <h3 class="white--text">ผู้รับผลประโยชน์ / Beneficiary</h3>
          </v-card-title>
          <v-card-text class="px-8">
            <v-row align="center">
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-radio-group mandatory v-model="beneficiaryIsLegalHeir">
                      <v-radio
                        label="ทายาทตามกฎหมาย / The Legal Heir"
                        :value="0"
                        class="mb-4"
                      ></v-radio>
                      <v-radio label="อื่นๆ / Others" :value="1" class="mb-4"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>

                <v-row v-if="showBfForm">
                  <v-col cols="6">
                    <v-text-field
                      label="ชื่อ-สกุล /First Name-Surname *"
                      outlined
                      class="name-input"
                      v-model="beneficiaryFullName"
                      :rules="ruleFullName"
                      counter="100"
                      :maxlength="100"
                    >
                    <template v-slot:label>
                            <h4>
                              ชื่อ-สกุล /First Name-Surname 
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                    
                    </v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      :items="relationList"
                      label="ความสัมพันธ์ /Relationship *"
                      outlined
                      :rules="ruleRequire"
                      v-model="beneficiaryRelation"
                    >
                    <template v-slot:label>
                            <h4>
                              ความสัมพันธ์ /Relationship 
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import { mapFields } from "vuex-map-fields";

export default {
  props: {
    disable: Boolean,
  },
  data() {
    return {
      relationList: [
        "WIFE",
        "HUSBAND",
        "FATHER",
        "MOTHER",
        "BROTHER",
        "SISTER",
        "DAUGHTER",
        "SON",
        "OTHER",
      ],
    };
  },
  computed: {
    showBfForm() {
      if (this.$store.getters.beneficiaryIsLegalHeir == 1) {
        return true;
      } else {
        return false;
      }
    },

    //If user select as a Non Legal heir
    ruleFullName() {
      const rules = [];
      const pattern = /\b[A-Z]* [A-Z]*( [A-Z])?\b/;

      const rule_notnull = (v) => (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_pattern = (v) => pattern.test(v) || "รูปแบบของชื่อไม่ถูกต้อง (ชื่อ**เว้นวรรค**นามสกุล) / Invalid name";

      if (this.$store.getters.beneficiaryIsLegalHeir != 0) {
        rules.push(rule_notnull);
        rules.push(rule_pattern);
      }

      return rules;
    },

    ruleRequire() {
      const rules = [];
      const rule_notnull = (v) => (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";

      if (this.$store.getters.beneficiaryIsLegalHeir != 0) {
        rules.push(rule_notnull);
      }

      return rules;
    },

    ...mapFields([
      "beneficiaryInfo.beneficiaryRelation",
      "beneficiaryInfo.beneficiaryFullName",
      "beneficiaryInfo.beneficiaryIsLegalHeir",
    ]),
  },
};
</script>

<style>

</style>