import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Plan from '../views/Plan.vue'
import Register from '../views/Register.vue'
import Verify from '../views/Verify.vue'
import Email from '../views/Email.vue'
import Payment from '../views/Payment.vue'
import Complete from '../views/Complete.vue'
import PathHandler from '../views/PathHandler.vue'
import TestPayment from '../views/TestPayment.vue'
import ViewError from '../views/Error.vue'
import store from "../store"


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/planselect',
    name: 'Plan',
    component: Plan,

    beforeEnter: (to, from, next) => {

      if (store.getters.isAccept == 1) {
        next()
      } else {
        next({ name: 'Home' })
      }
    },

  },
  {
    path: '/register',
    name: 'Register',
    component: Register,

    beforeEnter: (to, from, next) => {
      if (store.getters.isAccept == 1) {
        next()
      } else {
        next({ name: 'Home' })
      }
    }
  },
  {
    path: '/verify',
    name: 'Verify',
    component: Verify,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAccept == 1) {
        next()
      } else {
        next({ name: 'Home' })
      }
    }
  },
  {
    path: '/verifyemail',
    name: 'Email',
    component: Email,
    beforeEnter: (to, from, next) => {
      if (store.getters.isAccept == 1) {
        next()
      } else {
        next({ name: 'Home' })
      }
    }
  },
  {
    path: '/payment/:refid',
    name: 'Payment',
    component: Payment
  },
  {
    path: '/registercomplete',
    name: 'Complete',
    component: Complete
  },
  {
    path:'/testpayment',
    name:'TestPayment',
    component: TestPayment
  },
  {
    path:'/error',
    name:'Error',
    component: ViewError
  },
  // * Path Handler => just in case user input random url 
  {
    path:"*",
    name:'PathHandler',
    component:PathHandler
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
