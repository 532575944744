<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title class="d-flex bluemain px-8">
            <h3 class="white--text">รูปแบบใบเสร็จรับเงิน /Receipt Format</h3>
          </v-card-title>
          <v-card-text class="px-8">
            <v-row>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-radio-group v-model="billingOption" mandatory>
                      <v-radio
                        label="ในนามบุคคล (ผู้ถือกรมธรรม์) /Detail as a Insured (Policyholder)"
                        :value="0"
                        class="mb-4"
                      ></v-radio>
                      <v-radio
                        label="ในนามบริษัท /Detail as a Company"
                        :value="1"
                        class="mb-4"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
                <v-row v-if="disableAddress">
                  <v-col cols="12">
                    <v-row>
                      <v-col>
                        <v-text-field
                          label="ชื่อบริษัท /Name of Company *"
                          outlined
                          :rules="ruleAddress"
                          v-model="comName"
                          counter="50"
                          :maxlength="50"
                        >
                          <template v-slot:label>
                            <h4>
                              ชื่อบริษัท /Name of Company
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="สาขา /Branch *"
                          outlined
                          :rules="ruleAddress"
                          v-model="comBranch"
                          counter="25"
                          :maxlength="25"
                        >
                          <template v-slot:label>
                            <h4>
                              สาขา /Branch
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="เลขประจำตัวผู้เสียภาษี /Taxpayer ID Number *"
                          outlined
                          :rules="ruleAddress"
                          v-model="vatNumber"
                          counter="25"
                          :maxlength="25"
                        >
                          <template v-slot:label>
                            <h4>
                              เลขประจำตัวผู้เสียภาษี /Taxpayer ID Number
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="ที่อยู่ /Addresss *"
                          outlined
                          :rules="ruleCompany"
                          v-model="comAddress"
                          counter="100"
                          :maxlength="100"
                        >
                          <template v-slot:label>
                            <h4>
                              ที่อยู่ /Addresss
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          label="จังหวัด /Province *"
                          outlined
                          :items="provinceList"
                          item-text="provinceEng"
                          return-object
                          @change="getProvinceDetail(province)"
                          v-model="province"
                          :rules="ruleRequire"
                        >
                          <template v-slot:label>
                            <h4>
                              จังหวัด /Province
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          label="เขต/อำเภอ /District *"
                          outlined
                          
                          :rules="ruleRequire"
                          :items="districtList"
                          return-object
                          item-text="districtEngShort"
                          v-model="district"
                          @change="getSubDistrict(district)"
                        >
                          <template v-slot:label>
                            <h4>
                              เขต/อำเภอ /District
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          label="แขวง/ตำบล /Sub District *"
                          outlined
                          :rules="ruleRequire"
                          :items="subdistrictList"
                          return-object
                          item-text="tambonEngShort"
                           v-model="subDistrict"
                          @change="getZipCode(subDistrict)"
                        >
                          <template v-slot:label>
                            <h4>
                              แขวง/ตำบล /Sub District
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="รหัสไปรษณีย์ /Zip Code *"
                          outlined
                          v-model="zipCode"
                          readonly
                          :rules="ruleRequire"
                        >
                          <template v-slot:label>
                            <h4>
                              รหัสไปรษณีย์ /Zip Code
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import axios from "axios";
export default {
  data() {
    return {
      districtList: [],
      subdistrictList: [],
      provinceList: [],
      provinceDetailList: [],
    };
  },

  methods: {
    getZipCode: function (object) {
      this.zipCode = object.postCodeMain;
      this.$store.commit("setComAddress", object);
    },

    getProvinceDetail: async function (object) {
      this.district = null;
      this.subDistrict = null;
      this.zipCode = null;
      const provinceId = object.provinceID;
      await axios
        .get(`/template/getdistrict/${provinceId}`)
        .then((res) => {
          const { subDistrict, district } = res.data;
          this.districtList = district;
          this.provinceDetailList = subDistrict;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    getSubDistrict: function (object) {
      this.subdistrictList = this.provinceDetailList.filter(
        (subDistrict) => subDistrict.districtId === object.districtId
      );
    },
  },

  computed: {
    ruleRequire() {
      const rules = [];
      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      rules.push(rule_notnull);
      return rules;
    },

    ruleAddress() {
      const rules = [];
      const pattern_com_name = /(?:^|\W)ชื่อบริษัท(?:$|\W)/;
      const pattern_com_branch = /(?:^|\W)Branch(?:$|\W)/;
      const pattern_com_vat = /(?:^|\W)Tax Id(?:$|\W)/;
      const pattern_eng = /^[a-zA-Z0-9/ ().-]*$/i;

      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_syntax_name = (v) =>
        !pattern_com_name.test(v) ||
        `ห้ามใส่คำดังนี้ "ชื่อบริษัท" "Branch" "Tax Id" / Do not fill "ชื่อบริษัท", "Branch" and "Tax Id".`;
      const rule_syntax_branch = (v) =>
        !pattern_com_branch.test(v) ||
        `ห้ามใส่คำดังนี้ "ชื่อบริษัท" "Branch" "Tax Id" / Do not fill "ชื่อบริษัท", "Branch" and "Tax Id".`;
      const rule_syntax_vat = (v) =>
        !pattern_com_vat.test(v) ||
        `ห้ามใส่คำดังนี้ "ชื่อบริษัท" "Branch" "Tax Id" / Do not fill "ชื่อบริษัท", "Branch" and "Tax Id".`;
      const rule_eng = (v) =>
        pattern_eng.test(v) ||
        "รูปแบบไม่ถูกต้อง (ภาษาอังกฤษเท่านั้น) / English Only.";

      if (this.$store.getters.billingOption != 0) {
        rules.push(rule_notnull);
        rules.push(rule_syntax_name);
        rules.push(rule_syntax_branch);
        rules.push(rule_syntax_vat);
        rules.push(rule_eng);
      }
      return rules;
    },

    ruleCompany() {
      const rules = [];
      const pattern = /[`~,.<>;':"=_+]/;
      const pattern_eng = /^[a-zA-Z0-9/ ().-]*$/i;
      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_syntax = (v) =>
        !pattern.test(v) ||
        "ห้ามมีอักษรพิเศษในช่องนี้ / Special character is not allowed.";
      const rule_eng = (v) =>
        pattern_eng.test(v) ||
        "รูปแบบไม่ถูกต้อง (ภาษาอังกฤษเท่านั้น) / English Only.";

      if (this.$store.getters.billingOption != 0) {
        rules.push(rule_notnull);
        rules.push(rule_syntax);
        rules.push(rule_eng);
      }
      return rules;
    },

    disableAddress() {
      if (this.$store.getters.billingOption == 0) {
        return false;
      } else {
        return true;
      }
    },

    ...mapFields([
      "billingInfo.billingOption",
      "billingInfo.comName",
      "billingInfo.vatNumber",
      "billingInfo.comBranch",
      "billingInfo.comAddress",
      "billingInfo.comAddressObject",

      "tempBillingAddress.province",
      "tempBillingAddress.district",
      "tempBillingAddress.subDistrict",
      "tempBillingAddress.zipCode",
    ]),
  },

  created: async function () {
    axios
      .get("/template/getprovince")
      .then((res) => {
        this.provinceList = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

<style>
</style>