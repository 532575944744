<template>
  <v-container class="hero px-16" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg" elevation="18" max-width="600px">
          <v-card-text class="px-8 pt-6">
            <v-row>
              <v-col cols="12">
                <v-form
                  ref="travelreg"
                  class="mb-n4"
                  lazy-validation
                  v-model="valid"
                >

                <v-autocomplete
                    :items="tripTypes"
                    item-text="name"
                    item-value="value"
                    outlined
                    v-model="selectedTripType"
                    :rules="ruleRequire"
                  >   
                    <template v-slot:append-outer>
                      <v-tooltip right color="white">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> error_outline </v-icon>
                        </template>
                        <h3 class="red--text">
                          แผนประกันภัยแบบรายปี : ไม่จำกัดจำนวนครั้งต่อปี โดยคุ้มครองสูงสุดไม่เกิน 180 วันต่อการเดินทางในแต่ละครั้ง
                        </h3>
                        <h3 class="red--text font-weight-light">
                          Annual - Multi trip insurance plan :  Unlimited number of times per year 
                          with maximum coverage <br>not  exceeding 180 days per trip.
                        </h3>
                      </v-tooltip>
                    </template>
                    <template v-slot:label>
                      <h3>
                        รูปแบบการเดินทาง /Trip Type
                        <span class="red--text">*</span>
                      </h3>
                    </template>
                  </v-autocomplete>

                  <v-text-field
                    outlined
                    readonly
                    v-model="startCountry"
                    :rules="ruleRequire"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip right color="white">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> error_outline </v-icon>
                        </template>
                        <h3 class="red--text">
                          กรมธรรม์ประกันภัยให้ความคุ้มครองเมื่อท่านออกเดินทางจากประเทศไทยเท่านั้น
                        </h3>
                        <h3 class="red--text font-weight-light">
                          The Policy will provide cover when the country of
                          departure is Thailand only.
                        </h3>
                      </v-tooltip>
                    </template>
                    <template v-slot:label>
                      <h3>
                        ประเทศต้นทาง /Departure Country
                        <span class="red--text">*</span>
                      </h3>
                    </template>
                  </v-text-field>
                  <v-autocomplete
                    :items="desCountryList"
                    item-text="country"
                    outlined
                    v-model="desCountry"
                    :rules="ruleRequire"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip right color="white">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> error_outline </v-icon>
                        </template>
                        <h3 class="red--text">
                          * หากเดินทางหลายประเทศ โปรดระบุ “WORLDWIDE”
                        </h3>
                        <h3 class="red--text font-weight-light">
                          Please select "WORLD WIDE" for multi-countries.
                        </h3>
                        <h3 class="red--text">
                          * เดินทางไปประเทศในกลุ่มเชงเก้น โปรดระบุ “SCHENGEN”
                        </h3>
                        <h3 class="red--text font-weight-light">
                          Please select "SCHENGEN" for Schengen countries.
                        </h3>
                        <h3 class="red--text">
                          * เดินทางไปประเทศในกลุ่มเชงเก้น (เพื่อยื่นวีซ่า)
                          และประเทศอื่นๆ โปรดระบุ “SCHENGEN AND WORLDWIDE”
                        </h3>
                        <h3 class="red--text font-weight-light">
                          Please select "SCHENGEN AND WORLDWIDE" for Schengen
                          countries (Apply Visa) and other countries.
                        </h3>
                      </v-tooltip>
                    </template>
                    <template v-slot:label>
                      <h3>
                        ประเทศปลายทาง /Destination Country
                        <span class="red--text">*</span>
                      </h3>
                    </template>
                  </v-autocomplete>
                  <v-menu
                    v-model="departMenu"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    offset-y
                    max-width="256"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="departDate"
                        v-on="on"
                        v-bind="attrs"
                        readonly
                        outlined
                      >
                        <template v-slot:append-outer>
                          <v-tooltip right color="white">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on"> error_outline </v-icon>
                            </template>
                            <h3 class="red--text">
                              กรุณาซื้อกรมธรรม์ล่วงหน้าก่อนการเดินทางอย่างน้อย 2
                              ชั่วโมง
                            </h3>
                            <h3 class="red--text font-weight-light">
                              Please purchase insurance at least two hours
                              before travelling.
                            </h3>
                          </v-tooltip>
                        </template>
                        <template v-slot:label>
                          <h3>
                            วันที่เดินทางออกจากประเทศไทย /Departure date from
                            Thailand
                            <span class="red--text">*</span>
                          </h3>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="departDate"
                      no-title
                      scrollable
                      full-width
                      @input="departMenu = false"
                      :min="disableStartDt"
                      :max="limitThreeMonth"
                    ></v-date-picker>
                  </v-menu>
                  <v-menu
                    v-model="returnMenu"
                    transition="scale-transition"
                    :close-on-content-click="false"
                    offset-y
                    max-width="256"
                    :disabled="selectedTripType === 'annualTrip'"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="returnDate"
                        v-on="selectedTripType === 'annualTrip' ? {} : on"
                        v-bind="selectedTripType === 'annualTrip' ? {} : attrs"
                        readonly
                        :rules="validateTravelDays"
                        outlined
                      >
                        <template v-slot:append-outer v-if="selectedTripType === 'annualTrip'">
                          <v-tooltip right color="white">
                            <template v-slot:activator="{ on }">
                              <v-icon v-on="on"> info_outline </v-icon>
                            </template>
                            <h3 class="orange--text">
                              รายปี: วันที่เดินทางกลับถูกกำหนดอัตโนมัติเป็น 365 วันหลังจากวันออกเดินทาง
                            </h3>
                            <h3 class="orange--text font-weight-light">
                              Annual trip: Return date is automatically set to 365 days after departure date
                            </h3>
                          </v-tooltip>
                        </template>
                        <template v-slot:label>
                          <h3>
                            วันที่เดินทางกลับถึงประเทศไทย /Arrival date to Thailand
                            <span class="red--text">*</span>
                          </h3>
                        </template>
                      </v-text-field>
                    </template>
                    <v-date-picker
                      v-model="returnDate"
                      no-title
                      scrollable
                      full-width
                      :min="disableReturn"
                      :max="maximumDays"
                      @input="returnMenu = false"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                    readonly
                    outlined
                    :value="calDays"
                    :rules="validateTravelDays"
                  >
                    <template v-slot:append-outer>
                      <v-tooltip right color="white">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on"> error_outline </v-icon>
                        </template>
                        <h3 class="red--text">
                          ระยะเวลาการเดินทางต่างประเทศแต่ละครั้งสูงสุดไม่เกิน
                          180 วัน
                        </h3>
                        <h3 class="red--text font-weight-light">
                          The period of each overseas trip does not exceed 180
                          days.
                        </h3>
                      </v-tooltip>
                    </template>

                    <template v-slot:label>
                      <h3>จำนวนวันเดินทาง /Number of Days</h3>
                    </template>
                  </v-text-field>
                  <v-text-field
                    label="รหัสโปรโมชัน (ถ้ามี)"
                    outlined
                    v-model="promotionCode"
                  >
                  </v-text-field>
                </v-form>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row class="mt-3" justify="space-between">
              <v-col cols="auto">
                <h2>เบี้ยประกันเริ่มต้นเพียง</h2>
                <p>Premium starts at only</p>
              </v-col>
              <v-col cols="auto">
                <h1>{{ calPremium }}</h1>
              </v-col>
              <v-col cols="auto">
                <h2>บาท</h2>
                <p>THB</p>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="px-8 pb-7">
            <v-btn block color="#003E92" x-large @click="toPlan">
              <h2 class="font-weight-bold white--text">ซื้อเลย / Buy Now</h2>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "../router";
import moment from "moment";
import axios from "axios";
import { mapFields } from "vuex-map-fields";
import formatnum from "../unity/format-number";

export default {
  data() {
    return {
      departMenu: false,
      returnMenu: false,
      desCountryList: [],
      premium: 158,
      daysOfTravel: this.calDays,
      premiumTable: [],
      disableStartDt: moment().format("YYYY-MM-DD"),
      valid: true,
      daysRules: [(calDays) => calDays <= 180 || "วันเดินทางเกิน 180 วัน"],
      tripTypes: [
        { name: "รายเที่ยว / Single Trip", value: "singleTrip" },
        { name: "รายปี / Annual-Multi Trip", value: "annualTrip" }
      ],
      selectedTripType: "singleTrip",
    };
  },

  methods: {
    toPlan() {
      if (this.$refs.travelreg.validate()) {
        this.$store.commit("setnumberofDay", this.calDays);
        this.$store.commit("setTripType", this.selectedTripType);
        this.$store.commit("isAcceptCreated");
        window.scrollTo(0, 0);
        router.push({ name: "Plan" });
      } else {
        router.push({ name: "Error" });
      }
    },
  },

  computed: {
    ...mapFields([
      "travelInfo.startCountry",
      "travelInfo.desCountry",
      "travelInfo.departDate",
      "travelInfo.returnDate",
      "planInfo.promotionCode",
    ]),

    ruleRequire() {
      const rules = [];
      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";

      rules.push(rule_notnull);

      return rules;
    },

    calDays() {
      // ถ้าเป็นรายปี ให้เซ็ตค่าเป็น 365 วันทันที
      if (this.selectedTripType === "annualTrip") {
        return 365;
      }
      
      // ถ้าเป็นรายเที่ยว คำนวณตามปกติ
      const date1 = new Date(this.$store.getters.departDate);
      const date2 = new Date(this.$store.getters.returnDate);
      const diff =
        (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24) > 0
          ? (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24)
          : 0;
      if (diff == 0) {
        return 0;
      } else {
        return diff + 1;
      }
    },

    calPremium() {
      let result = 0;
      
      // ตรวจสอบประเภทการเดินทาง
      if (this.selectedTripType === "singleTrip") {
        // สำหรับการเดินทางรายเที่ยว ใช้ตารางราคาตามวัน
        for (let i = 0; i < this.premiumTable.length; i++) {
          if (this.calDays <= this.premiumTable[i].preDays) {
            result = this.premiumTable[i].prePremiumTotal;
            break;
          }
        }
        
        // หากไม่มีข้อมูลหรือยังไม่ได้คำนวณวันเดินทาง ให้ใช้ค่า default
        if (result === 0) {
          result = 158;
        }
      } else if (this.selectedTripType === "annualTrip") {
        // สำหรับการเดินทางรายปี กำหนดราคาคงที่
        result = 2423;
      }
      
      return formatnum.format(result);
    },

    validateTravelDays() {
      const rules = [];
      
      if (this.calDays == 0) {
        rules.push("วันเดินทางไม่ถูกต้อง / invalid days");
      } else if (this.selectedTripType === "singleTrip" && this.calDays > 180) {
        rules.push("รายเที่ยว: จำนวนวันเดินทางสูงสุด 180 วัน / Single trip: Maximum 180 days");
      }
      // ไม่ต้องตรวจสอบจำนวนวันสำหรับรายปี เพราะเราล็อคเป็น 365 วันแล้ว

      return rules;
    },

    disableReturn() {
      return this.$store.getters.getDisableDt;
    },


    maximumDays() {
      // ถ้าไม่มีวันเดินทางออก ให้ใช้วันสูงสุดจาก store
      if (!this.departDate) {
        return this.$store.getters.getMaxReturnDt;
      }
      
      const departDateObj = new Date(this.departDate);
      if (this.selectedTripType === "singleTrip") {
        // รายเที่ยว - จำกัด 180 วันจากวันเดินทางออก
        const maxDate = new Date(departDateObj);
        maxDate.setDate(departDateObj.getDate() + 179); // +179 เพราะนับรวมวันแรก
        return maxDate.toISOString().substr(0, 10);
      } else {
        // รายปี - กำหนดเป็น 365 วันจากวันเดินทางออกเสมอ
        const maxDate = new Date(departDateObj);
        maxDate.setDate(departDateObj.getDate() + 364); // +364 เพราะนับรวมวันแรก
        return maxDate.toISOString().substr(0, 10);
      }
    },

    limitThreeMonth(){
      return this.$store.getters.getLimitDt;
    }
  },

  created: async function () {

    this.selectedTripType = this.$store.getters.tripType || "singleTrip";
    //GET ALL COUNTRY
    axios
      .get("/template/getcountry")
      .then((res) => {
        this.desCountryList = res.data;
      })
      .catch(() => {
        router.push({ name: "Error" });
      });

    axios
      .get("/template/getdefaultpremium")
      .then((res) => {
        this.premiumTable = res.data;
      })
      .catch(() => {
        router.push({ name: "Error" });
      });
  },

  watch: {
    selectedTripType(newType) {
      // เก็บค่าประเภทการเดินทางลงใน store
      this.$store.commit("setTripType", newType);
      
      if (newType === "annualTrip") {
        // กรณีเลือกรายปี
        if (this.departDate) {
          // ล็อควันกลับเป็น 365 วันหลังจากวันออกเดินทาง
          const departDateObj = new Date(this.departDate);
          const newReturnDate = new Date(departDateObj);
          newReturnDate.setDate(departDateObj.getDate() + 364); // +364 เพราะนับรวมวันเริ่มต้น
          this.returnDate = newReturnDate.toISOString().substr(0, 10);
        }
        
        // กำหนดราคาคงที่สำหรับแบบรายปี
        this.premium = 2423;
      } else {
        // กรณีเลือกรายเที่ยว
        // ไม่ต้องล็อควันที่เดินทางกลับ - คำนวณราคาตามปกติ
        if (this.calDays > 0 && this.premiumTable.length > 0) {
          for (let i = 0; i < this.premiumTable.length; i++) {
            if (this.calDays <= this.premiumTable[i].preDays) {
              this.premium = this.premiumTable[i].prePremiumTotal;
              break;
            }
          }
        } else {
          this.premium = 158;
        }
      }
    },
    
    // เพิ่ม watcher สำหรับการเปลี่ยนแปลงวันที่เดินทางออก
    departDate(newDate) {
      if (newDate) {
        // ถ้าเป็นรายปี ให้ล็อควันกลับเป็น 365 วันหลังจากวันออกเดินทางเสมอ
        if (this.selectedTripType === "annualTrip") {
          const departDateObj = new Date(newDate);
          const newReturnDate = new Date(departDateObj);
          newReturnDate.setDate(departDateObj.getDate() + 364); // +364 เพราะนับรวมวันเริ่มต้น
          this.returnDate = newReturnDate.toISOString().substr(0, 10);
        } 
        // กรณีรายเที่ยว ตรวจสอบและปรับวันที่เดินทางกลับตามปกติ
        else if (this.returnDate) {
          const departDateObj = new Date(newDate);
          const returnDateObj = new Date(this.returnDate);
          const diffDays = Math.floor((returnDateObj - departDateObj) / (1000 * 60 * 60 * 24)) + 1;
          
          if (diffDays > 180) {
            // ถ้าวันที่เดินทางกลับเกินขีดจำกัด 180 วัน ให้ปรับเป็นวันสุดท้ายที่สามารถเลือกได้
            const newReturnDate = new Date(departDateObj);
            newReturnDate.setDate(departDateObj.getDate() + 179);
            this.returnDate = newReturnDate.toISOString().substr(0, 10);
          }
        }
      }
    }
  },
};
</script>

<style scoped>
.hero {
  background-image: url("../assets/img/Main.png");
  background-size: cover;
}
</style>