<template>
  <div>
    <Stepper :value=3 class="mb-10"></Stepper>
    <VerifyEmail class="mb-10"></VerifyEmail>
  </div>
</template>

<script>
import verifyemail from "../components/verifyemail.vue";
import stepper from "../components/stepper.vue";
export default {
  components: {
    VerifyEmail: verifyemail,
    Stepper: stepper,
  },

  data() {
    return {};
  },
};
</script>

<style>
</style>