<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-row justify="space-between">
          <v-col cols="auto">
            <v-btn block x-large color="white" elevation="3" @click="back()">
              <h4 class="bluemain--text">ย้อนกลับ / Back</h4>
            </v-btn>
          </v-col>
          <v-col cols="auto">
            <v-btn
              block
              x-large
              color="white"
              elevation="3"
              @click="next()"
              :disabled="disable"
            >
              <h4 class="bluemain--text">ดำเนินการต่อ / Continue</h4>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "../router";
export default {
  methods: {
    back() {
      window.scrollTo(0, 0);
      router.push({ name: "Register" });
    },

    next() {
      window.scrollTo(0, 0);
      this.$store.dispatch('postRegister')
      this.$store.commit('destroyCondition');
      router.push({ name: "Email" });
    },
  },
  computed: {
    disable() {
      return !this.$store.getters.condition;
    },
  },
};
</script>

<style>
</style>