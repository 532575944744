<template>
  <v-container >
    <v-row justify="center">
      <v-col cols="auto">
        <v-row>
          <v-col xs="12" md="3" sm="12" lg="3">
            <v-card flat>
              <v-row justify="center">
                <v-col cols="auto">
                  <Plus></Plus>
                </v-col>
              </v-row>
              <v-card-subtitle class="mb-n5">
                <v-row justify="center">
                  <v-col cols="auto">
                    <h3 class="black--text" >เจ็บป่วย อุบัติเหตุฉุกเฉิน</h3>
                    <v-divider class="mt-2 underline"></v-divider>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="auto">
                    <p class="text-center black--text font-weight-medium">ให้ความคุ้มครองค่ารักษาพยาบาลทั่วทุกมุมโลก จากเหตุการณ์ไม่คาดฝัน ซิ้อแผนประกันไว้อุ่นใจหายห่วง</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col xs="12" md="3" sm="12" lg="3">
            <v-card flat>
              <v-row justify="center">
                <v-col cols="auto">
                  <Ambulance></Ambulance>
                </v-col>
              </v-row>
              <v-card-subtitle class="mb-n5">
                <v-row justify="center">
                  <v-col cols="auto">
                    <h3 class="black--text">บริการช่วยเหลือฉุกเฉิน</h3>
                    <v-divider class="mt-2 underline"></v-divider>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="auto">
                    <p class="text-center black--text font-weight-medium">ด้านการแพทย์ ระหว่างการเดินทาง 24 ชั่วโมง ด้วยวงเงินสูงถึง 1 ล้านเหรียญสหรัฐ</p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

           <v-col xs="12" md="3" sm="12" lg="3">
            <v-card flat>
              <v-row justify="center">
                <v-col cols="auto">
                  <Covid></Covid>
                </v-col>
              </v-row>
              <v-card-subtitle class="mb-n5">
                <v-row justify="center">
                  <v-col cols="auto">
                    <h3 class="black--text ">ทุกแผนคุ้มครองไวรัส COVID-19</h3>
                    <v-divider class="mt-2 underline"></v-divider>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="auto">
                    <p class="text-center black--text font-weight-medium">เจ็บป่วยรักษาได้ ตามวงเงินแต่ละแผน รายละเอียดเพิ่มเติม <a class="text-decoration-underline font-weight-bold black--text" style="cursor: pointer;" href="assets/pos/file/covid-announcement.pdf" download>คลิกที่นี่</a></p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>

          <v-col xs="12" md="3" sm="12" lg="3">
            <v-card flat>
              <v-row justify="center">
                <v-col cols="auto">
                  <Airplane></Airplane>
                </v-col>
              </v-row>
              <v-card-subtitle class="mb-n5">
                <v-row justify="center">
                  <v-col cols="auto">
                    <h3 class="black--text">แผนประกันทั้งหมด</h3>
                    <v-divider class="mt-2 underline"></v-divider>
                  </v-col>
                </v-row>
              </v-card-subtitle>
              <v-card-text>
                <v-row justify="center">
                  <v-col cols="auto">
                    <p class="text-center black--text font-weight-medium">อ่านแผนประกันทั้งหมด <a class="text-decoration-underline font-weight-bold black--text" style="cursor: pointer;" href="https://www.thaihealth.co.th/journey-safety/">คลิกที่นี่</a></p>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import plus from "../assets/icon/plus.vue"
import ambulance from '../assets/icon/ambulance.vue'
import covid  from '../assets/icon/covid.vue'
import airplane from '../assets/icon/airplane.vue'
export default {
  components: {
    Plus: plus,
    Ambulance:ambulance,
    Covid:covid,
    Airplane:airplane
  },
};
</script>

<style scoped>

.underline{
  max-height: 10px !important;
  height: 6px;
  background-color:#003E92;
  border-radius: 5px;
}


</style>