<template>
  <v-container>
      <v-row justify="center">
          <v-col cols="12">
              <v-btn block x-large color="white" elevation="3" @click="back()">
              <h4 class="bluemain--text">ย้อนกลับ / Back</h4>
            </v-btn>
          </v-col>
      </v-row>
  </v-container>
</template>

<script>
import router from "../router";
export default {
    methods: {
        back() {
             window.scrollTo(0,0);
            router.push({ name: "Home"})
        }
    }
}
</script>

<style>

</style>