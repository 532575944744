<template>
  <v-row justify="center">
    <v-col cols="auto">
      <v-card>
        <v-card-title>
          <h2>ทดลองจ่ายเงิน</h2>
        </v-card-title>
        <v-card-text>
          <v-text-field label="รายละเอียดการจ่าย" type="text"> </v-text-field>

          
        </v-card-text>
        <v-card-actions>
          <v-btn @click="postPayment"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      payment_url: "https://demo2.2c2p.com/2C2PFrontEnd/RedirectV3/payment",
      version: "8.5",
      merchant_id: "764764000004288",
      order_id: Date.now(),
      currency: "764",
      amount: 4000,
      hash_value: null,
      payment_description: "Shabushi",
    };
  },
  methods: {
    postPayment() {
      document.forms.myform.submit();
    },
  },
  computed: {
    toStringDigit() {
      return this.amount.toString().padStart(12, "0");
    },
  },
  created: async function () {
    axios
      .post("/payment/hash", {
        version: this.version,
        merchant_id: this.merchant_id,
        order_id: this.order_id,
        currency: this.currency,
        amount: this.toStringDigit,
        payment_description: this.payment_description,
      })
      .then((res) => {
        console.log(res);
        this.hash_value = res.data;
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style >
</style>