<template>
  <v-app-bar absolute app flat class="transparent px-16 mt-2">
    
        <Logo class="d-inline-flex linktohome" ></Logo>
  
      <v-spacer></v-spacer>
    
        <Sublogo class="d-inline-flex linktohome" ></Sublogo>
      
  </v-app-bar>
</template>

<script>
import logo from "../assets/icon/logo.vue";
import sublogo from "../assets/icon/sublogo.vue";

export default {
  components: {
    Logo: logo,
    Sublogo: sublogo,
  },
  methods: {
    
  },
};
</script>

<style scoped>
h3 {
  color: #003e92;
}


</style>

