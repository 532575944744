<template>
  <v-container>
    <v-card>
      <v-card-title class="bluemain px-8">
        <h4 class="white--text">แผนประกันที่คุณเลือก / Your Insurance Plan</h4>
      </v-card-title>
      <v-card-text class="mt-7 px-7">
        <h1 class="d-inline my-2 black--text">{{ planInfo.planName }}</h1>
        <v-list two-line>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title> <h3>เบี้ยประกันรวม</h3> </v-list-item-title>
              <v-list-item-subtitle> <h3>Total Premium</h3> </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <h2>
                {{ formatingNum(planInfo.premium.prePremiumTotal) }}
                &nbsp;THB&nbsp;
                <span
                  class="font-weight-bold subtitle-2 black--text"
                  style="font-family: 'Noto Sans Thai UI' !important"
                >
                  (ค่าเบี้ยประกันภัยรวมอากรแล้ว)
                </span>
                <br />
              </h2>
              <div class="font-weight-bold black--text">
                (Premium is inclusive of stamp duty)
              </div>
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-divider class="black my-4"></v-divider>
        <h2 class="black--text">ความคุ้มครอง / Coverage</h2>
        <v-list>
          <template v-for="item in planInfo.coverageList">
            <v-list-item :key="item.insdId" class="my-0" two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <h3>{{ item.cvpDescriptionThai }}</h3>
                </v-list-item-title>
                <v-list-item-subtitle v-html="item.cvpDescriptionEng">
                  {{ item.cvpDescriptionEng }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-list-item-action-text>
                  <h2 class="black--text">
                    {{ formatingNum(item.cvpAmount) }}&nbsp;
                    {{ item.cvpUnit }}
                  </h2>
                </v-list-item-action-text>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import formatnum from "@/unity/format-number";
export default {
  data() {
    return {
      planInfo: this.$store.getters.getPlanInfo,
      travelInfo: this.$store.getters.getTravelInfo,
    };
  },
  methods: {
    formatingNum(num) {
      return formatnum.format(num);
    },
  },
};
</script>

<style>
</style>