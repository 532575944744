<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title class="d-flex bluemain px-8">
            <h3 class="white--text">
              ข้อมูลผู้เอาประกัน (ผู้ถือกรมธรรม์) / Insured Information
              (Policyholder)
            </h3>
          </v-card-title>
          <v-card-text class="px-8">
            <v-row>
              <v-col cols="12">
                <h3 class="red--text">
                  *กรุณากรอกข้อมูลเป็นภาษาอังกฤษเท่านั้น / Please fill in
                  English Only.
                </h3>

                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col xs="12" sm="12" md="12">
                        <v-select
                          :items="titleList"
                          label="คำนำหน้าชื่อ /Title Name *"
                          outlined
                          v-model="title"
                          :rules="ruleRequire"
                        >
                          <template v-slot:label>
                            <h4>
                              คำนำหน้าชื่อ /Title Name
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          label="ชื่อ /First Name *"
                          outlined
                          v-model.trim="name"
                          class="name-input"
                          :rules="ruleName"
                          counter="40"
                          :maxlength="40"
                        >
                          <template v-slot:label>
                            <h4>
                              ชื่อ /First Name
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col xs="12" sm="12" md="6">
                        <v-text-field
                          label="นามสกุล /Surname *"
                          outlined
                          class="name-input"
                          v-model.trim="lastName"
                          :rules="ruleName"
                          counter="40"
                          :maxlength="40"
                        >
                          <template v-slot:label>
                            <h4>
                              นามสกุล /Surname
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <h5 class="red--text">
                      * ผู้เดินทางต้องมีอายุ 1-70 ปีบริบูรณ์เท่านั้น <br />
                      <span class="white--text">*</span>For ages 1-70 years old only
                    </h5>
                    <v-row>
                      <v-col cols="6">
                        <v-menu
                          v-model="birthDateMenu"
                          transition="scale-transition"
                          :close-on-content-click="false"
                          offset-y
                          max-width="256"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="birthDate"
                              v-on="on"
                              v-bind="attrs"
                              readonly
                              outlined
                              :rules="ruleRequire"
                            >
                              <template v-slot:label>
                                <h4>
                                  วัน เดือน ปีเกิด /Date of Birth
                                  <span class="red--text">*</span>
                                </h4>
                              </template>
                            </v-text-field>
                          </template>
                          <v-date-picker
                            v-model="birthDate"
                            no-title
                            scrollable
                            full-width
                            @input="birthDateMenu = false"
                            :min="minBirthDate"
                            :max="maxBirthDate"
                            :readonly="disable"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="6">
                        <v-select
                          label="สัญชาติ /Nationality *"
                          :items="nationalityList"
                          v-model="nationality"
                          outlined
                          :rules="ruleRequire"
                        >
                          <template v-slot:label>
                            <h4>
                              สัญชาติ /Nationality
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col v-if="switchTextfield">
                        <v-text-field
                          label="เลขบัตรประชาชน /ID Card No.*"
                          outlined
                          v-model.trim="idCard"
                          counter="13"
                          :maxlength="13"
                          :rules="ruleIdCard"
                        >
                          <template v-slot:label>
                            <h4>
                              เลขบัตรประชาชน /ID Card No.
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          label="เลขที่พาสปอร์ต /Passport No. *"
                          outlined
                          counter="20"
                          :maxlength="20"
                          v-model.trim="passportNumber"
                          :rules="rulePassport"
                        >
                          <template v-slot:label>
                            <h4>
                              เลขที่พาสปอร์ต /Passport No.
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-text-field
                          label="เบอร์โทรศัพท์มือถือ /Mobile No. *"
                          outlined
                          v-model.trim="phoneNumber"
                          counter="10"
                          :maxlength="10"
                          :rules="ruleNumber"
                        >
                          <template v-slot:label>
                            <h4>
                              เบอร์โทรศัพท์มือถือ /Mobile No.
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="อีเมล /Email *"
                          outlined
                          counter="100"
                          :maxlength="100"
                          v-model.trim="email"
                          :rules="ruleEmail"
                        >
                          <template v-slot:label>
                            <h4>
                              อีเมล /Email
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="ที่อยู่ /Address *"
                          outlined
                          v-model.trim="address"
                          :rules="ruleAddress"
                          counter="100"
                          :maxlength="100"
                        >
                          <template v-slot:label>
                            <h4>
                              ที่อยู่ /Address
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          label="จังหวัด /Province *"
                          outlined
                          :items="provinceList"
                          item-text="provinceEng"
                          return-object
                          @change="getProvinceDetail(province)"
                          v-model="province"
                          :rules="ruleRequire"
                        >
                          <template v-slot:label>
                            <h4>
                              จังหวัด /Province
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-autocomplete
                          label="เขต/อำเภอ /District *"
                          outlined
                          :items="districtList"
                          item-text="districtEngShort"
                          return-object
                          @change="getSubDistrict(district)"
                          v-model="district"
                          :rules="ruleRequire"
                        >
                          <template v-slot:label>
                            <h4>
                              เขต/อำเภอ /District
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6">
                        <v-autocomplete
                          label="แขวง/ตำบล /Sub District *"
                          outlined
                          v-model="subDistrict"
                          :rules="ruleRequire"
                          :items="subdistrictList"
                          return-object
                          item-text="tambonEngShort"
                          @change="getZipCode(subDistrict)"
                        >
                          <template v-slot:label>
                            <h4>
                              แขวง/ตำบล /Sub District
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-autocomplete>
                      </v-col>
                      <v-col cols="6">
                        <v-text-field
                          label="รหัสไปรษณีย์ /Zip Code *"
                          outlined
                          v-model="zipCode"
                          readonly
                          :rules="ruleRequire"
                        >
                          <template v-slot:label>
                            <h4>
                              รหัสไปรษณีย์ /Zip Code
                              <span class="red--text">*</span>
                            </h4>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import router from "../router";
import moment from "moment";
import axios from "axios";
import { mapFields } from "vuex-map-fields";
import { mapGetters } from "vuex";

export default {
  props: {
    disable: Boolean,
  },

  data() {
    return {
      nationalityList: ["THAI", "OTHER"],
      titleList: ["MASTER", "MR.", "MISS", "MRS."],
      ///
      birthDateMenu: false,
      ///
      ///คำนวณหาอายุจาก req ที่ได้รับมาใหม่
      minBirthDate: moment(
        moment(this.$store.getters.returnDate)
          .subtract(71, "years")
          .format("YYYY-MM-DD")
      )
        .add(1, "days")
        .format("YYYY-MM-DD"),
      maxBirthDate: moment(this.$store.getters.departDate)
        .subtract(1, "years")
        .format("YYYY-MM-DD"),
      ///
      districtList: [],
      subdistrictList: [],
      provinceList: [],
      provinceDetailList: [],
    };
  },

  methods: {
    getZipCode: function (object) {
      this.zipCode = object.postCodeMain;
      this.$store.commit("setHolderAddress", object);
    },

    getProvinceDetail: async function (object) {
      this.district = null;
      this.subDistrict = null;
      this.zipCode = null;
      const provinceId = object.provinceID;
      await axios
        .get(`/template/getdistrict/${provinceId}`)
        .then((res) => {
          const { subDistrict, district } = res.data;
          this.districtList = district;
          this.provinceDetailList = subDistrict;
        })
        .catch(() => {
          router.push({ name: "Error" });
        });
    },

    getSubDistrict: function (object) {
      this.subdistrictList = this.provinceDetailList.filter(
        (subDistrict) => subDistrict.districtId === object.districtId
      );
    },
  },

  computed: {
    switchTextfield() {
      if (this.$store.getters.nationality == "OTHER") {
        this.$store.commit("setIsThai", 2);
        return false;
      } else {
        this.$store.commit("setIsThai", 1);
        return true;
      }
    },

    ruleRequire() {
      const rules = [];
      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";

      rules.push(rule_notnull);

      return rules;
    },

    ruleNumber() {
      const rules = [];
      const pattern = /^\d{1,10}$/;

      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_pattern = (v) =>
        pattern.test(v) ||
        "รูปแบบไม่ถูกต้อง (ตัวเลขเท่านั้น) / Numerical Only.";
      const rule_phone = (v) =>
        (!!v && v.length == 10) ||
        "กรุณากรอกหมายเลขโทรศัพท์ / Phone number need to be 10 digits.";
      rules.push(rule_phone);
      rules.push(rule_notnull);
      rules.push(rule_pattern);

      return rules;
    },

    ruleAddress() {
      const rules = [];
      const pattern = /^[a-zA-Z0-9/ ().-]*$/i;

      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_pattern = (v) =>
        pattern.test(v) ||
        "รูปแบบไม่ถูกต้องและห้ามมีอักษรพิเศษ (ภาษาอังกฤษเท่านั้น) / English Only.";
      rules.push(rule_notnull);
      rules.push(rule_pattern);

      return rules;
    },

    ruleName() {
      const rules = [];
      const pattern = /^[a-zA-Z ]*$/i;

      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_pattern = (v) =>
        pattern.test(v) ||
        "รูปแบบไม่ถูกต้อง (ภาษาอังกฤษเท่านั้น) / English Only.";
      rules.push(rule_notnull);
      rules.push(rule_pattern);

      return rules;
    },

    ruleIdCard() {
      const rules = [];
      const pattern = /^\d{1,13}$/;
      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_pattern = (v) =>
        pattern.test(v) ||
        "รูปแบบไม่ถูกต้อง (ตัวเลขเท่านั้น) / Numerical Only.";
      const rule_card = (v) =>
        (!!v && v.length == 13) ||
        "กรุณากรอกหมายเลขบัตรประชาชน / Id card need to be 13 digits.";

      if (this.$store.getters.nationality == "THAI") {
        rules.push(rule_card);
        rules.push(rule_notnull);
        rules.push(rule_pattern);
      }
      return rules;
    },

    rulePassport() {
      const rules = [];
      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      rules.push(rule_notnull);
      return rules;
    },

    ruleEmail() {
      const rules = [];
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const rule_notnull = (v) =>
        (v != null && v != "") || "กรุณากรอกช่องนี้ / This field is required.";
      const rule_mail = (v) =>
        pattern.test(v) || "อีเมลไม่ถูกต้อง / Invalid email address.";

      rules.push(rule_notnull);
      rules.push(rule_mail);

      return rules;
    },

    ...mapFields([
      "holderInfo.title",
      "holderInfo.name",
      "holderInfo.lastName",
      "holderInfo.birthDate",
      "holderInfo.nationality",
      "holderInfo.idCard",
      "holderInfo.passportNumber",
      "holderInfo.phoneNumber",
      "holderInfo.email",
      "holderInfo.address",
      "holderInfo.addressObject",

      "tempHolderAddress.province",
      "tempHolderAddress.district",
      "tempHolderAddress.subDistrict",
      "tempHolderAddress.zipCode",
    ]),

    ...mapGetters([
      "holderProvince",
      "holderDistrict",
      "holderSubDistrict",
      "autocompleteAddress",
    ]),
  },

  created: async function () {
    axios
      .get("/template/getprovince")
      .then((res) => {
        this.provinceList = res.data;
      })
      .catch(() => {
        router.push({ name: "Error" });
      });
  },
};
</script>

<style >
.v-input__slot .v-text-field__slot label {
  color: black;
  font-weight: bold;
}

.v-select__slot label {
  color: black !important;
  font-weight: bold;
}

.v-input__slot .v-radio label {
  color: black !important;
  font-weight: bold;
}

.name-input input {
  text-transform: uppercase;
}
</style>