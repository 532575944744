<template>
  <div>
    <Stepper :value="3" class="mb-10"></Stepper>
    <Plan></Plan>
    <Travel></Travel>
    <Holder></Holder>
    <Beneficiary></Beneficiary>
    <Billing></Billing>
    <Policy></Policy>
    <Condition></Condition>
    <NextStepVerify></NextStepVerify>
    <OicWarning></OicWarning>
  </div>
</template>

<script>
import stepper from "../components/stepper.vue";
import oicWarning from "../components/oic-warning";
import plan from "../components/data-cards/plan-verify";
import travel from '../components/data-cards/travel'
import holder from '../components/data-cards/holder'
import billing from '../components/data-cards/billing'
import policy from '../components/data-cards/policy'
import condition from '../components/conditioninfo'
import beneficiary from '../components/data-cards/beneficiary'
import nextstepverify from "@/buttons/nextstep-verify.vue";

export default {
  data() {
    return {
      form: true,
    };
  },
  components: {
    Stepper: stepper,
    Plan: plan,
    Holder:holder,
    Billing:billing,
    Condition:condition,
    Policy:policy,
    Beneficiary:beneficiary,
    NextStepVerify: nextstepverify,
    OicWarning: oicWarning,
    Travel:travel
  },
};
</script>

<style>
</style>