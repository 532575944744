<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title class="d-flex bluemain px-8">
            <h3 class="white--text">กรุณาตรวจสอบอีเมล / Please Check Your Email</h3>
          </v-card-title>
          <v-card-text class="px-8">
            <v-row justify="center" class="my-10">
              <v-col cols="auto">
                <h1 class="text-center mb-3" style="line-height:1.5;">
                  กรุณาตรวจสอบอีเมลของท่านเพื่อทำการยืนยันตัวตน
                  ก่อนที่จะชำระเงิน ซึ่งได้ส่งถึงอีเมลตามที่ท่านระบุไว้แล้ว
                  ขอบคุณค่ะ
                </h1>
                <h3 class="text-center">
                  Please check your email for verification before make a payment
                  which had already been sent to your designated email. Thank
                  you.
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="errCheck" width="400" persistent>
      <v-card>
        <v-card-title>
          <h2 class="red--text">Error</h2>
        </v-card-title>
        <v-card-text>
          <h2>พบข้อผิดพลาด</h2>
          <h3>กรุณาติดต่อ support</h3>
        </v-card-text>
        <v-card-actions>
          <v-btn block large @click="backToMenu"> กลับสู่หน้าหลัก </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { bus } from "@/event-bus.js";
import router from "@/router";
export default {
  data() {
    return {
      errCheck: false,
    };
  },
  methods: {
    backToMenu() {
      window.scrollTo(0, 0);
      router.push({ name: "Home" });
    },
  },
  created: function () {
    bus.$on("postRegErr", () => {
      console.log("Error");
      this.errCheck = true;
    });

    window.addEventListener("hashchange", function () {
      console.log('change')
    });
  },
};
</script>

<style>
</style>