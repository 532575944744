<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex bluemain ">
        <h4 class="white--text">ผู้รับผลประโยชน์ / Beneficiary</h4>
      </v-card-title>
      <v-card-text class="pa-7">
        <div class="d-flex justify-space-between">
          <h3
            v-if="beneficiaryInfo.beneficiaryIsLegalHeir == 0"
            class="black--text "
          >
            ทายาทโดยธรรม / THE LEGAL HEIR
          </h3>
          <h3 v-else class="black--text  beneficiary">
            {{ beneficiaryInfo.beneficiaryFullName }}
          </h3>
          <h3
            class="black--text"
            v-if="beneficiaryInfo.beneficiaryIsLegalHeir != 0"
          >
            <span class="font-weight-regular">ความสัมพันธ์ / Relationship</span>
            {{ beneficiaryInfo.beneficiaryRelation }}
          </h3>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      beneficiaryInfo: this.$store.getters.beneficiaryInfo,
    };
  },
};
</script>

<style scoped>
.beneficiary {
  text-transform: uppercase;
}
</style>