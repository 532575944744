<template>
  <v-container>
    <v-card flat>
      <v-row>
        <v-col cols="auto">
          <h4 class="red--text">หมายเหตุ :</h4>
          <h4 class="red--text">Remark</h4>
        </v-col>
        <v-col cols="auto">
          <div class="bluemain--text">
            1.
            ท่านจะได้รับกรมธรรม์จากบริษัททางอีเมลอัตโนมัติทันทีหลังจากชำระเงินเรียบร้อยแล้ว
          </div>
          <div class="bluemain--text">
            You will automatically receive the policy from the company via email
            immediately after the payment has been made.
          </div>
          <div class="bluemain--text">
            2. ถ้าข้อมูลด้านบนที่ท่านกรอกในระบบไว้ไม่ถูกต้อง
            ท่านต้องสมัครออนไลน์โดยกรอกข้อมูลใหม่อีกครั้ง
            <a
              class="red--text text-decoration-underline font-weight-bold"
              style="cursor: pointer"
              @click="backToHome"
              >คลิกที่นี่</a
            >
          </div>
          <div class="bluemain--text">
            If the above information you entered is incorrect. You need to apply
            online again.
            <a
              class="red--text text-decoration-underline font-weight-bold"
              style="cursor: pointer"
              @click="backToHome"
              >Click Here</a
            >
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import router from "@/router";
export default {
  methods: {
    backToHome() {
      window.scrollTo(0, 0);
      router.push({ name: "Home" });
    },
  },
};
</script>

<style>
</style>