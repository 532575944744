<template>
  <div>
    <v-row no-gutters justify="center">
      <v-col
        v-for="item in planList"
        :key="item.id"
        sm="12"
        md="5"
        lg="5"
        xl="3"
      >
        <v-card class="mx-5 my-2 rounded-lg " >
          <v-card-title class="d-flex justify-center bluemain mb-5">
            <h3 class="white--text">{{ item.name }}</h3>
          </v-card-title>
          <v-card-text>
            <v-banner>
              <v-row justify="center">
                <v-col cols="auto">
                  <h1 class="text-center black--text d-inline">
                    {{ formatingNum(item.premium.prePremiumTotal) }}
                  </h1>
                  <p class="text-right black--text d-inline">THB</p>
                </v-col>
              </v-row>
            </v-banner>

            <v-banner>
              <div v-if="item.id == 1 || item.id == 2">
                <v-icon class="px-2 red--text"> clear </v-icon>
                <div class="d-inline-flex red--text">
                  ไม่สามารถยื่นวีซ่าในกลุ่มเช้งเก้น <br />
                  Not applicable for Schengen
                </div>
              </div>
              <div v-else>
                <v-icon class="px-2 blue--text "> done_outline </v-icon>
                <div class="d-inline-flex blue--text ">
                  สามารถยื่นวีซ่าในกลุ่มเช้งเก้น <br />
                  Applicable for Schengen
                </div>
              </div>
            </v-banner>
            <v-list two-line>
              <v-subheader
                ><h3 class="text-center black--text">ความคุ้มครอง/Coverage</h3></v-subheader
              >

              <template v-for="item in item.detail" >
                <v-list-item :key="item.covGroupFK" class="my-0 " >
                  <v-list-item-content>
                    <v-list-item-title class="black--text">
                      {{ item.cvpDescriptionShortThai }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="black--text">
                      {{ item.cvpDescriptionShortEng }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text class="black--text">
                      <h3 class="font-weight-regular">
                        {{ formatingNum(item.cvpAmount) }}&nbsp;
                        {{ item.cvpUnit }}
                      </h3>
                    </v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>

            <v-row class="mb-n5">
              <v-col cols="12">
                <v-row class="mb-n3">
                  <v-col cols="12">
                    <v-btn
                      block
                      dense
                      color="white"
                      @click="
                        viewDetail(item);
                        detailModal = true;
                      "
                      min-height="4rem"
                      class="font-weight-bold"
                    >
                      ข้อมูลเพิ่มเติม
                      <br />
                      More Information
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-btn
                      block
                      large
                      color="bluemain"
                      @click="toReg(item)"
                      min-height="4rem"
                    >
                      <h3 class="white--text">
                        เลือกแผนนี้ <br />
                        Select
                      </h3>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="detailModal" max-width="1000px" hide-overlay scrollable>
      <v-card height="100%">
        <v-card-title class="d-flex justify-center bluemain">
          <h3 class="white--text">{{ selectedPlan.name }}</h3>
           <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="detailModal = false">
              <v-icon class="white--text">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-card-title>
        <v-card-text >
          <v-banner>
            <v-row justify="center">
              <v-col cols="auto">
                <h1 class="text-center black--text d-inline">
                  {{ formatingNum(selectedPlan.premium.prePremiumTotal) }}
                </h1>
                <p class="text-right black--text d-inline">THB</p>
              </v-col>
            </v-row>
          </v-banner>

          

          <v-banner>
            <div v-if="selectedPlan.id == 1 || selectedPlan.id == 2">
              <v-icon class="px-2 red--text"> clear </v-icon>
              <div class="d-inline-flex red--text">
                ไม่สามารถยื่นวีซ่าในกลุ่มเช้งเก้น <br />
                Not applicable for Schengen
              </div>
            </div>
            <div v-else>
              <v-icon class="px-2 blue--text"> done_outline </v-icon>
              <div class="d-inline-flex blue--text">
                สามารถยื่นวีซ่าในกลุ่มเช้งเก้น <br />
                Applicable for Schengen
              </div>
            </div>
          </v-banner>

          <v-list two-line>
            <template v-for="item in selectedPlan.detail">
              <v-list-item :key="item.covGroupFK">
                <v-list-item-content>
                  <v-list-item-title class="black--text">
                    {{ item.cvpDescriptionThai }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="item.cvpDescriptionEng">
                    {{ item.cvpDescriptionEng }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-list-item-action-text>
                    <h3 class="font-weight-regular">
                      {{ formatingNum(item.cvpAmount) }}&nbsp;
                      {{ item.cvpUnit }}
                    </h3>
                  </v-list-item-action-text>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-list>
          <v-divider class="black"></v-divider>

          <v-list three-line>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="red--text">
                  เงื่อนไขการรับประกันภัย
                </v-list-item-title>
                <v-list-item-subtitle class="wrap-text">
                  <p>
                    • เบี้ยประกันภัยสำหรับผู้เอาประกันภัยอายุ 1 – 70 ปี บริบูรณ์
                    / Premium for insured person between 1-70 years of age.
                  </p>
                  <p>
                    • การขอวีซ่าไปยังกลุ่มประเทศสหภาพยุโรป-เชงเก้น
                    ต้องมีผลประโยชน์ค่ารักษาพยาบาลขั้นต่ำ 1,500,000 บาท
                    (ไม่สามารถใช้แผน Economy Class หรือ Premium Class ได้)
                    กรุณาเลือกแผน Business Class หรือ First Class เท่านั้น /
                    Minimum medical expenses for applying VISA to European
                    Union-Schengen destination is 1,500,000 baht. (Cannot select
                    Economy Class Plan or Premium Class Plan) Please select
                    Business Class Plan หรือ First Class Plan only.
                  </p>

                  <p>
                    • ผู้ขอเอาประกันภัยต้องมีถิ่นฐานอยู่ในประเทศไทย
                    ผู้ขอเอาประกันภัยต้องเริ่มต้นเดินทางออกจากประเทศไทยเท่านั้น
                    และสิ้นสุดที่ ประเทศไทยเท่านั้น The insured must reside in
                    Thailand. The insured must begin trip from Thailand only and
                    end trip in Thailand only.
                  </p>

                  <p>
                    • แผนประกันภัยแบบรายเที่ยว : คุ้มครองสูงสุดไม่เกิน 180 วันต่อการเดินทางในแต่ละครั้ง / Single trip insurance plan:  Maximum             
                    coverage not exceeding 180 days per trip.
                  </p>
                  <p>
                    • แผนประกันภัยแบบรายปี : ไม่จำกัดจำนวนครั้งต่อปี โดยคุ้มครองสูงสุดไม่เกิน 180 วันต่อการเดินทางในแต่ละครั้ง / Annual - Multi Trip             
                    insurance plan: Unlimited number of times per year with maximum coverage not exceeding 180 days per trip.
                  </p>

                  <p>
                    • ผู้ขอเอาประกันภัยจะต้องมีสุขภาพแข็งแรงสมบูรณ์
                    ไม่มีอวัยวะส่วนใดส่วนหนึ่งพิการ
                    และไม่ได้เดินทางเพื่อการรักษาพยาบาลใดๆ The insured must be
                    in good health condition with no disability. And that the
                    purpose of our travel is not to receive any medical
                    treatment whatever.
                  </p>

                  <p>
                    •
                    ผู้ขอเอาประกันภัยสามารถซื้อความคุ้มครองกรมธรรม์ประกันอุบัติเหตุการเดินทางต่างประเทศได้
                    1 ฉบับต่อการเดินทาง 1 ครั้ง Applicant can only apply for one
                    insurance policy over the same time period.
                  </p>

                  <p>
                    • อาณาเขตที่ไม่คุ้มครอง: เนปาล (เฉพาะการเดินเขาแบบ Trekking
                    แคมป์ ปิ้งบนภูเขา และการไต่หน้าผา/ปีนเขา) Exclusion
                    countries: Nepal (hiking, trekking, base camping and
                    climbing)
                  </p>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="red--text">
                  ข้อยกเว้นที่สำคัญ
                </v-list-item-title>
                <v-list-item-subtitle class="wrap-text">
                  <p>
                    • ไม่คุ้มครองสภาพทางการแพทย์ที่เป็นอยู่แล้ว (Pre-existing
                    conditions) / Not cover for Pre-existing conditions.
                  </p>
                  <p>
                    • การฆ่าตัวตาย การพยายามฆ่าตัวตาย หรือการทำร้ายร่างกายตัวเอง
                    / Suicide, attempted suicide or any intentional self injury.
                  </p>
                  <p>
                    • การทำแท้ง การแท้งลูก การมีครรภ์ การคลอดบุตร / Abortion,
                    miscarriage, normal pregnancy.
                  </p>
                  <p>
                    • การเล่นกีฬาอันตราย / Dangerous sport.
                  </p>
                  <p>
                    • การกระทำของผู้เอาประกันภัย ขณะอยู่ภายใต้ฤทธิ์สุรา
                    หรือยาเสพติดให้โทษ / Action of the insured while under the
                    influence of alcohol or addictive drugs.
                  </p>
                  <p>
                    • ในขณะที่ผู้เอาประกันภัยขับขี่หรือโดยสารรถจักรยานยนต์ /
                    While the insured is riding or traveling on a motorcycle.
                  </p>
                  <p>
                    •
                    กรณีการประกันภัยแบบรายปี (Annual - Multi Trip) ไม่คุ้มครองขณะการพักระหว่างเดินทางกลับถึงที่อยู่อาศัยในประเทศไทย /
                     In the case of annual insurance (Annual - Multi Trip), it does not cover the stay while returning to your residence in Thailand.
                  </p>
                  <p>
                    • ข้อยกเว้นความคุ้มครองอื่น ๆ เป็นไปตามกรมธรรม์ประกันภัย /
                    Other exclusions shall be referred to the insurance policy.
                  </p>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import router from "../router";
import axios from "axios";
import formatnum from "../unity/format-number";
export default {
  data() {
    return {
      detailModal: false,
      selectedPlan: {
        name: "",
        id: "",
        premium: "",
        detail: [],
      },
      planList: [],
      slide: null,
    };
  },

  methods: {
    toReg(item) {
      this.$store.commit("setTravelInfo", item);
      window.scrollTo(0, 0);
      router.push({ name: "Register" });
    },

    viewDetail(item) {
      this.selectedPlan = item;
    },
    formatingNum: function (num) {
      return formatnum.format(num);
    },
  },

  created: async function () {
    const tripType = this.$store.getters.tripType;
    if(tripType === "singleTrip"){
      await axios.get(`/template/getinsurplan/${this.$store.getters.numberofDay}`)
      .then((res) => {
        this.planList = res.data;
      })
      .catch(() => {
        router.push({ name: "Error" });
      });
    } else {
      await axios.get(`/template/getinsurplan/365`)
      .then((res) => {
        this.planList = res.data;
      })
      .catch(() => {
        router.push({ name: "Error" });
      });
    }
  },
};
</script>

<style>
.wrap-text {
  -webkit-line-clamp: unset !important;
}

.wrap-text p {
  margin-bottom: 0.75rem;
  color: black;
}
</style>