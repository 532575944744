<template>
  <div>
      <Stepper :value=4 class="mb-10"></Stepper>
      <RegCom class="mb-10"></RegCom>
  </div>
</template>

<script>
import stepper from '../components/stepper.vue'
import regcom from '../components/register-complete.vue'
export default {
    components: {
        Stepper: stepper,
        RegCom: regcom
    }
}
</script>

<style>

</style>