<template>
  <v-container>
    <v-card>
      <v-card-title class="d-flex bluemain">
        <h4 class="white--text">
          ข้อมูลผู้เอาประกัน (ผู้ถือกรมธรรม์) / Insured Information
          (Policyholder)
        </h4>
      </v-card-title>
      <!-- Holder -->
      <v-row no-gutters>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">
                ชื่อผู้เอาประกันภัย
              </h3>
              <h4 class="font-weight-regular">Insured Name</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3 class="holdername">
                {{ holderInfo.title }} {{ holderInfo.name }}
                {{ holderInfo.lastName }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Date of birth -->
      <v-row no-gutters>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">วัน/เดือน/ปีเกิด</h3>
              <h4 class="font-weight-regular">Date of birth</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>{{ holderInfo.birthDate }}</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Nationality -->
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">สัญชาติ</h3>
              <h4 class="font-weight-regular">Nationality</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>{{ holderInfo.nationality }}</h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <!-- Passport -->
      <v-row no-gutters>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">เลขที่พาสปอร์ต</h3>
              <h4 class="font-weight-regular">Passport no.</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>{{ holderInfo.passportNumber }}</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- ID Card -->
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">เลขที่บัตรประชาชน</h3>
              <h4 class="font-weight-regular">ID Card No.</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3 v-if="holderInfo.nationality != `OTHER`">
                {{ holderInfo.idCard }}
              </h3>
              <h3 v-else>-</h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <!-- Phone -->
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">
                เบอร์โทรศัพท์มือถือ
              </h3>
              <h4 class="font-weight-regular">Mobile No.</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>{{ holderInfo.phoneNumber }}</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- Email -->
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text>
              <h3 class="font-weight-regular black--text">อีเมล</h3>
              <h4 class="font-weight-regular">Email</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="3">
          <v-card outlined tile>
            <v-card-text class="black--text">
              <h3>{{ holderInfo.email }}</h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="3">
          <v-card outlined tile class="rounded-bl">
            <v-card-text>
              <h3 class="font-weight-regular black--text">ที่อยู่</h3>
              <h4 class="font-weight-regular">Address</h4>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card outlined tile class="rounded-br">
            <v-card-text>
              <h3 class="black--text">
                {{ holderInfo.address }}
                {{ holderInfo.addressObject.tambonEngShort }}
                {{ holderInfo.addressObject.districtEngShort }}
                {{ holderInfo.addressObject.provinceEng }}
                {{ holderInfo.addressObject.postCodeMain }}
              </h3>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      holderInfo: this.$store.getters.holderInfo,
    };
  },
};
</script>

<style scoped>
.v-sheet >>> .v-card {
  height: 100%;
}

.holdername {
  text-transform: uppercase;
}
</style>