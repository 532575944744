<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12">
        <v-card class="rounded-lg">
          <v-card-title class="d-flex bluemain">
            <h4 class="white--text">ข้อกำหนดและเงื่อนไข</h4>
          </v-card-title>
          <v-card-text class="px-8 pt-4">
            <v-row>
              <v-col cols="12">
                <div class="policy-inform">
                  <p class="font-weight-bold">
                    ข้าพเจ้าขอรับรองว่า ข้าพเจ้ามีสิทธิ
                    มีอำนาจกระทำการแทนและ/หรือแถลงข้อความเพื่อ
                    จัดให้มีการซื้อกรมธรรม์ให้กับข้าพเจ้าเอง หรือ ให้กับผู้อื่น
                    และยินยอมให้ทางบริษัทฯใช้และเข้าถึง E-mail
                    ระบบข้อมูลอิเล็กทรอนิกส์และข้อมูลส่วนบุคคลที่ให้ไว้ข้างต้น
                    เพื่อวัตถุประสงค์ ในการแจ้งผลการอนุมัติ
                    การให้บริการด้านการประกันภัย การตลาด การนำเสนอบริการ
                    ผลิตภัณฑ์ใหม่ๆ การติดต่อสื่อสาร
                    และสำหรับเรื่องอื่นๆที่เกี่ยวข้องตามความจำเป็นอย่างสมเหตุสมผล
                  </p>
                  <p>
                    I here by certify that I have the right and authority to act
                    on behalf of and/or make statements in order to purchase
                    insurance policy(ies) for myself or others. I understand and
                    give consent to and/or permission for the company to use,
                    access and/or acknowledge E-mail(s), electronic information
                    channels/systems and personal information provided for the
                    purposes of the approval of insurance contract(s), insurance
                    services, marketing, offering services, new products,
                    communication, storage and for other related matters as
                    reasonably necessary.
                  </p>
                </div>
                <div class="policy-inform">
                  <p class="font-weight-bold">
                    ข้าพเจ้ายินยอมให้บริษัทฯ จัดเก็บ ใช้
                    และเปิดเผยข้อเท็จจริงเกี่ยวกับสุขภาพและข้อมูลของข้าพเจ้าต่อสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
                    เพื่อประโยชน์ในการกำกับดูแลธุรกิจประกันภัย
                  </p>
                  <p>
                    I hereby consent to the company’s keeping use, and disclose
                    of the facts about my health and information to the Office
                    of Insurance Commission (OIC) for the benefits of
                    supervision of the insurance business.
                  </p>
                </div>
                <div class="policy-inform">
                  <p class="font-weight-bold">
                    ข้าพเจ้าขอยืนยันว่าผู้เอาประกันภัยทุกรายมีสุขภาพสมบูรณ์ดี
                    และปราศจากความพิการใดๆ และไม่เดินทางเพื่อการรักษาพยาบาลใดๆ
                  </p>
                  <p>
                    I hereby certify that all assureds are in good health and
                    have no any disabilities and are not travelling to obtain
                    any medically related treatment(s).
                  </p>
                </div>

                <div class="policy-inform">
                  <p class="font-weight-medeium bluemain--text">
                    คำแถลงตามรายการข้างบนเป็นความจริงทุกประการ
                    และให้ถือเป็นส่วนหนึ่งของสัญญาประกันภัยระหว่างข้าพเจ้ากับบริษัท
                    และข้าพเจ้าได้ อ่านและยอมรับสำหรับข้อตกลงเงื่อนไข
                    ข้อยกเว้นต่างๆ ตามที่ระบุไว้กรมธรรม์ รายละเอียดคลิก
                    <a
                      class="text-decoration-underline blue--text font-weight-bold"
                      style="cursor: pointer"
                      href="assets/pos/file/policy-wording-thai-online.pdf"
                      download
                    >
                      Policy Wording
                    </a>
                  </p>
                  <p class="font-weight-medeium bluemain--text">
                    The above statements are true, correct and shall be deemed
                    to be one part of the insurance contract between myself and
                    the company. And I have read and agreed for the terms,
                    conditions and exclusions of this policy. For details please
                    click
                    <a
                      class="text-decoration-underline blue--text font-weight-bold"
                      style="cursor: pointer"
                      href="assets/pos/file/policy-wording-eng-online.pdf"
                      download
                    >
                      Policy Wording
                    </a>
                  </p>
                </div>
              </v-col>
            </v-row>

            <v-row align="center" class="pa-5">
              <v-checkbox
                hide-details
                class="shrink mr-2 mt-0"
                v-model="condition"
              ></v-checkbox>
              <div v-bind:class="{ 'black--text': condition == true }">
                ข้าพเจ้ายอมรับและเข้าใจในข้อกำหนดและเงื่อนไขแห่งกรมธรรม์ประกันภัยนี้
                <br />
                I accept and understand the terms and conditions of this
                insurance policy.
              </div>
            </v-row>
            <v-row>
              <v-col>
                <h3 class="red--text">
                  กรุณายอมรับเงื่อนไข / Please accept term & conditions
                </h3>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapFields } from "vuex-map-fields";

export default {
  computed: {
    ...mapFields(["condition"]),
  },
};
</script>

<style scoped>
.policy-inform >>> p {
  margin-bottom: 0.25rem;
  color: black;
}

.policy-inform {
  margin: 0 0 1.5rem 0;
}
</style>