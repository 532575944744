import Vue from 'vue'
import Vuex from 'vuex'
import moment from 'moment'
import axios from 'axios'

Vue.use(Vuex)

import { getField, updateField } from 'vuex-map-fields';
import { bus } from '@/event-bus.js'

export default new Vuex.Store({
  state: {

    //Nav Guard ไม่ให้สามารถเข้า path การสมัครได้โดยตรง
    isAccept: 0,

    //Condition เงื่อนไขที่ให้ user กดติ้กถูกหน้า ตรวจเช็คข้อมูล
    condition: false,

    travelInfo: {
      startCountry: "Thailand",
      desCountry: "Worldwide",
      departDate: moment().format("YYYY-MM-DD"),
      returnDate: moment().add(1, 'days').format("YYYY-MM-DD"),
      presentDate: moment().format("YYYY-MM-DD"),
      numberofDay: null,
      tripType: "singleTrip", // เพิ่มบรรทัดนี้
    },

    planInfo: {
      planName: null,
      planId: null,
      planCode: null,
      premium: null,
      promotionCode: null,
      coverageList: [],
      travelType: "RoundTrip"
    },

    holderInfo: {
      title: null,
      name: null,
      lastName: null,
      birthDate: moment().subtract(1, "years").format("YYYY-MM-DD"),
      nationality: "THAI",
      idCard: null,
      passportNumber: null,
      phoneNumber: null,
      email: null,
      address: null,
      addressObject: {},
      isThai: 0
    },
    beneficiaryInfo: {
      beneficiaryIsLegalHeir: 0,
      beneficiaryRelation: null,
      beneficiaryFullName: null,
    },
    billingInfo: {
      billingOption: 0,
      comBranch: null,
      vatNumber: null,
      comAddress: null,
      comName: null,
      comAddressObject: {},
      comCountry: "Thailand"
    },
    policyRevOption: 0,

    tempHolderAddress: {
      province: null,
      district: null,
      subDistrict: null,
      zipCode: null,
    },

    tempBillingAddress: {
      province: null,
      district: null,
      subDistrict: null,
      zipCode: null,
    }
  },
  actions: {
    postRegister() {
      this.state.holderInfo.name = this.state.holderInfo.name.toUpperCase()
      this.state.holderInfo.lastName = this.state.holderInfo.lastName.toUpperCase()
      this.state.beneficiaryInfo.beneficiaryFullName = (this.state.beneficiaryInfo.beneficiaryIsLegalHeir != 0) ? this.state.beneficiaryInfo.beneficiaryFullName.toUpperCase() : null
      axios.post('/reg/createnewcustomer', {
        travelInfo: this.state.travelInfo,
        planInfo: this.state.planInfo,
        holderInfo: this.state.holderInfo,
        beneficiaryInfo: this.state.beneficiaryInfo,
        billingInfo: this.state.billingInfo,
        policyRevOption: this.state.policyRevOption
      }).catch(err => {
        console.log(err)
        bus.$emit("postRegErr")
      })
    }
  },
  modules: {
  },
  getters: {
    getField,
    nationality: state => state.holderInfo.nationality,
    beneficiaryRelation: state => state.beneficiaryInfo.beneficiaryRelation,
    billingOption: state => state.billingInfo.billingOption,
    planName: state => state.planInfo.planName,
    premium: state => state.planInfo.premium,
    coverageList: state => state.planInfo.coverageList,
    numberofDay: state => state.travelInfo.numberofDay,
    startCountry: state => state.travelInfo.startCountry,
    desCountry: state => state.travelInfo.desCountry,
    departDate: state => state.travelInfo.departDate,
    returnDate: state => state.travelInfo.returnDate,
    holderProvince: state => state.holderInfo.province,
    holderDistrict: state => state.holderInfo.district,
    holderSubDistrict: state => state.holderInfo.subDistrict,
    comProvince: state => state.billingInfo.comProvince,
    beneficiaryIsLegalHeir: state => state.beneficiaryInfo.beneficiaryIsLegalHeir,
    autocompleteAddress: state => state.autocompleteAddress,
    condition: state => state.condition,
    getTravelInfo: state => state.travelInfo,
    getPlanInfo: state => state.planInfo,
    holderInfo: state => state.holderInfo,
    beneficiaryInfo: state => state.beneficiaryInfo,
    billingInfo: state => state.billingInfo,
    policyRevOption: state => state.policyRevOption,
    isAccept: state => state.isAccept,
    tripType: state => state.travelInfo.tripType,

    getDisableDt(state) { //Disable Current Depart Date on Return Date Panel (ป้องกันไม่ให้ user เลือกวันกลับ หลังวันออกเดินทาง)
      let date = moment(state.travelInfo.departDate, "YYYY-MM-DD")
      date.add(1, "days")
      return date.format("YYYY-MM-DD")
    },

    getMaxReturnDt(state) {
      let max_date = moment(state.travelInfo.departDate, "YYYY-MM-DD");
      
      // ตรวจสอบประเภทการเดินทาง
      if (state.travelInfo.tripType === "singleTrip") {
        // สำหรับรายเที่ยว: 180 วัน (รวมวันที่เริ่มเดินทาง)
        max_date.add(179, "days");
      } else {
        // สำหรับรายปี: 1 ปี (รวมวันที่เริ่มเดินทาง)
        max_date.add(1, "year").subtract(1, "day");
      }
      
      return max_date.format("YYYY-MM-DD");
    },

    getLimitDt(state) {//ซื้อล่วงหน้าได้ไม้เกิน 3 เดือน
      let limit_date = moment(state.travelInfo.presentDate, "YYYY-MM-DD")
      limit_date.add(89, "days")
      return limit_date.format("YYYY-MM-DD")
    }
  },
  mutations: {
    updateField,

    setTravelInfo(state, payload) {
      state.planInfo.planName = payload.name
      state.planInfo.planId = payload.id
      state.planInfo.planCode = payload.planCode
      state.planInfo.premium = payload.premium
      state.planInfo.coverageList = payload.detail
    },

    setDesCountry(state, payload) {
      state.travelInfo.desCountry = payload
    },

    setnumberofDay(state, payload) {
      state.travelInfo.numberofDay = payload
    },

    setZipCode(state, payload) {
      state.holderInfo.zipCode = payload
    },

    setHolderAddress(state, payload) {
      state.holderInfo.addressObject = payload
    },

    setComAddress(state, payload) {
      state.billingInfo.comAddressObject = payload
    },

    setIsThai(state, payload) {
      state.holderInfo.isThai = payload
    },

    isAcceptCreated(state) {
      state.isAccept = 1
    },

    isAcceptDestroy(state) {
      state.isAccept = 0
    },

    destroyCondition(state) {
      state.condition = false
    },
    setTripType(state, payload) {
      state.travelInfo.tripType = payload;
    },

    setCustomerData(state, payload) {

      //Plan Information
      state.planInfo.planName = payload.insurance.insPlanName
      state.planInfo.premium = payload.insurance.insPremiumTotal
      state.planInfo.coverageList = payload.insuraceDetail

      //Travel Information
      state.travelInfo.desCountry = payload.travel.travelDesCountry
      state.travelInfo.departDate = payload.travel.travelDepartDate
      state.travelInfo.returnDate = payload.travel.travelReturnDate
      state.travelInfo.numberofDay = payload.travel.travelDays

      //Customer Profile
      state.holderInfo.title = payload.person.personTitle
      state.holderInfo.name = payload.person.personName
      state.holderInfo.birthDate = payload.person.personBirthDate
      state.holderInfo.nationality = payload.profile.cusNation
      state.holderInfo.passportNumber = payload.profile.cusPassportNo
      state.holderInfo.phoneNumber = payload.profile.cusPhoneNo
      state.holderInfo.idCard = payload.person.personCardId
      state.holderInfo.email = payload.profile.cusEmail
      state.holderInfo.address = payload.profile.cusAddress
      state.holderInfo.addressObject = {
        provinceEng: payload.address.provinceEng,
        districtEngShort: payload.address.districtEngShort,
        tambonEngShort: payload.address.tambonEngShort,
        postCodeMain: payload.address.postCodeMain
      }

      //Beneficiary
      if (payload.benefit.bfRelation != "THE LEGAL HEIR") {
        state.beneficiaryInfo.beneficiaryIsLegalHeir = 1
      } else {
        state.beneficiaryInfo.beneficiaryIsLegalHeir = 0
      }

      state.beneficiaryInfo.beneficiaryFullName = payload.personBeneficiary.personName
      state.beneficiaryInfo.beneficiaryRelation = payload.benefit.bfRelation

      //Billing Info
      //Check if are Company Billing 
      if (payload.billingPerson.personType == "BC") {
        state.billingInfo.billingOption = 1

        let bill_com_list = payload.billingProfile.cusAddress.split('ที่อยู่:')
        let bill_com_address = bill_com_list[0].trim()

        const company_split = bill_com_address.split(',')
        const bill_com_name = company_split[0]
        const bill_com_branch = company_split[1]
        const bill_com_vat_num = company_split[2]

        state.billingInfo.vatNumber = bill_com_vat_num.trim()
        state.billingInfo.comBranch = bill_com_branch.trim()
        state.billingInfo.comName = bill_com_name.trim()

        state.billingInfo.comAddress = bill_com_list[1].trim()
        state.billingInfo.comAddressObject = {
          provinceEng: payload.billingAddress.provinceEng,
          districtEngShort: payload.billingAddress.districtEngShort,
          tambonEngShort: payload.billingAddress.tambonEngShort,
          postCodeMain: payload.billingAddress.postCodeMain
        }
      } else {
        state.billingInfo.billingOption = 0
      }

      //policy option
      state.policyRevOption = payload.profile.cusSendPolicyByEmail
    }

  },
})
