<template>
  <v-container>
    <v-row cols="justify">
      <v-col>
        <v-card class="rounded-lg">
          <v-card-title class="d-flex red darken-3 px-8 text--center justify-center">
            <h3 class="white--text">พบปัญหา / Error !</h3>
          </v-card-title>
          <v-card-text class="pa-3">
              <h2 class="pa-3 text-center">กรุณาติดต่อ support</h2>
              <h2 class="pa-3 text-center black--text">02-202-9200</h2>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>