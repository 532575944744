<template>
  <v-container>
    <div>
      <h4 class="red--text">
        คำเตือนของสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย
        (คปภ.)
      </h4>
      <p class="red--text">
        ให้ตอบคำถามข้างต้นตามความเป็นจริงทุกข้อ
        หากผู้เอาประกันภัยปกปิดข้อความจริง หรือแถลงข้อความอันเป็นเท็จ
        จะมีผลให้สัญญานี้ตกเป็นโมฆียะ ซึ่งบริษัทมีสิทธิบอกล้างสัญญาประกันภัยได้
        ตามประมวลกฎหมายแพ่งและพาณิชย์ มาตรา 865
      </p>
    </div>
    <div>
      <h4 class="red--text">Warning of Office of Insurance Commission (OIC)</h4>
      <p class="red--text">
        The Applicant must truthfully answer all questions. Any concealment or
        misrepresentation of the truth may cause the Insurance Contract to be
        voidable. Accordingly, the company shall be entitled to avoid the
        Insurance Contract, according to the Civil and Commercial Code, Section
        865.
      </p>
    </div>
  </v-container>
</template>

<script>
export default {};
</script>

<style>
</style>