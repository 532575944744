<template>
  <v-container>
    <v-stepper v-model="value" class="rounded ">
      <v-stepper-header>
        <v-stepper-step :complete="value > 1" step="1" color="bluemain">
          เลือกแผนประกัน <br>
          Select Plan
        
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="value > 2" step="2" color="bluemain">
          ข้อมูลผู้เอาประกัน <br> Insured Information
        
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step :complete="value > 3" step="3" color="bluemain">
          ยืนยันข้อมูล <br> Confirmation
         
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" color="bluemain">
          ชำระเงิน <br> Payment
          
        </v-stepper-step>
      </v-stepper-header>
    </v-stepper>
  </v-container>
</template>

<script>
export default {
  props: {
    value: Number,
  },
};
</script>

<style >

</style>