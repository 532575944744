<template>
  <div>
      <ErrorCard></ErrorCard>
  </div>
</template>

<script>
import errorcard from '../components/error-card'
export default {
    components:{
        ErrorCard:errorcard
    }
}
</script>

<style>

</style>