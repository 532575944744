<template>
  <div>
    <Stepper :value="3" class="mb-10"></Stepper>
    <Plan></Plan>
    <Travel></Travel>
    <Holder></Holder>
    <Beneficiary></Beneficiary>
    <Billing></Billing>
    <Policy></Policy>
    <PaymentBtn></PaymentBtn>
    <WarningPayment></WarningPayment>
  </div>
</template>

<script>
import stepper from "../components/stepper.vue";
import travel from '../components/data-cards/travel'
import holder from "../components/data-cards/holder";
import beneficiary from "../components/data-cards/beneficiary";
import billing from "../components/data-cards/billing";
import plan from "../components/data-cards/plan-payment";
import policy from "../components/data-cards/policy";
import warningPayment from '../components/warning-payment'
import paymentBtn from "@/buttons/payment.vue";

export default {
  components: {
    Stepper: stepper,
    Billing: billing,
    Holder: holder,
    Beneficiary: beneficiary,
    Policy: policy,
    Plan: plan,
    Travel:travel,
    PaymentBtn: paymentBtn,
    WarningPayment:warningPayment,
  },
};
</script>

<style>
</style>