<template>
  <v-footer color="#003E92">
    <v-row class="footercontain" justify="center">
      <v-col cols="9">
        <v-row justify="space-between">
          <v-col cols="auto" v-for="item in footerList" :key="item.head">
            <v-row>
              <v-col cols="12">
                <h4>{{ item.head }}</h4>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div v-for="(n, index) in item.child" :key="index">
                  <div v-html="n">{{ n }}</div>
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <h4>เกี่ยวกับเรา</h4>
            <p class="description">
              เราเป็นบริษัทประกันสุขภาพที่มีการจดทะเบียนก่อตั้ง เมี่อปี พ.ศ.
              2522 ซึ่งเป็นหนึ่งในห้า บริษัทประสุขภาพรายแรกของประเทศ
              ด้วยฐานะการเงินที่มั่นคง การให้บริการที่มีคุณภาพฯ
              รายละเอียดเพิ่มเติม
              <a
                class="text-decoration-underline font-weight-bold white--text"
                style="cursor: pointer"
                href="https://www.thaihealth.co.th/company_profile"
                >คลิกที่นี่</a
              >
            </p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="auto">
            <h4>บริษัท ไทยประกันสุขภาพ จำกัด (มหาชน)</h4>
            <p class="footerdescription">
              121/88-89 อาคาร อาร์ เอส ทาวเวอร์ ชั้น 31 ถนนรัชดาภิเษก
            </p>
            <p class="footerdescription">แขวงดินแดง เขตดินแดง กรุงเทพฯ 10400</p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
export default {
  data() {
    return {
      footerList: [
        {
          head: "บริการลูกค้า",
          child: [
            `<a class="white--text" style="text-decoration: none; margin-bottom: 15px;" href="assets/pos/file/travel-promotion.pdf" download>โปรโมชั่น</a> `,
            `<a class="white--text" style="text-decoration: none; margin-bottom: 15px;" href="assets/pos/file/general-information.pdf" download>ข้อมูลผลิตภัณฑ์</a> `,
            `<a class="white--text" style="text-decoration: none; margin-bottom: 15px;" href="assets/pos/file/policy-wording-online.pdf" download>กรมธรรม์ประกันการเดินทาง</a> `,
            `<a class="white--text" style="text-decoration: none; margin-bottom: 15px;" href="assets/pos/file/advice-for-cliam.pdf" download>คำแนะนำการเรียกร้องสินไหม</a> `,
            `<a class="white--text" style="text-decoration: none; margin-bottom: 15px;" href="assets/pos/file/claim-form.pdf" download>แบบฟอร์มการเรียกร้องค่าสินไหม</a> `,
            `<a class="white--text" style="text-decoration: none; margin-bottom: 15px;" href="assets/pos/file/privacy-policy.pdf" download>นโยบายส่วนบุคคล</a> `,
            `<a class="white--text" style="text-decoration: none; margin-bottom: 15px;" href="https://www.thaihealth.co.th/privacynotice/" download> นโยบายคุ้มครองข้อมูลส่วนบุคคล  <span style="font-weight:700">(Privacy Notice)</span> </a> `,
          ],
        },  
        {
          head: "บริการตัวแทน",
          child: [
            `<p class="white--text " style="margin-bottom: 4px;">สนใจเป็นตัวแทน/นายหน้าฯ</p>`,
            `<p class="white--text " style="margin-bottom: 4px;">ติดต่อคุณวิชัย</p> `,
            `<p class="white--text " style="margin-bottom: 4px;">Tel: (+66) 02 202 9230,</p> `,
            `<p class="white--text " style="margin-bottom: 4px;">(+66) 81 499 8662</p> `,
            `<p class="white--text " style="margin-bottom: 4px;">Email: travel@thaihealth.co.th</p> `,
          ],
        },
        {
          head: "ช่องทางการติดต่อ",
          child: [
            `<p class="white--text" style="margin-bottom:4px;">สำหรับซื้อประกันภัยออนไลน์</p> `,
            `<p class="white--text" style="margin-bottom:4px;">Tel: (+66) 02 202 9230-31, (+66) 81 499 8662</p>`,
            `<p class="white--text" style="margin-bottom:4px;">Email: travel@thaihealth.co.th</p> `,
            `<p class="white--text" style="margin-bottom:4px;">Emergency/Claim Tel: (+66) 02 305 4485, (+66) 84 525 1119</p> `,
            `<p class="white--text" style="margin-bottom:4px;">Email: claim@thaihealth.co.th</p> `,
          ],
        },
      ],
    };
  },
};
</script>

<style scoped>
p,
h4 {
  color: white;
}

.footerdescription {
  margin-bottom: 0px;
}

.description{
  font-size: 16px;
  margin-bottom: 0px  ;
}

p {
  font-size: 12px;
}
</style>