<template>
  <div>
      <Stepper :value=1 class="mb-10"></Stepper>
      <PlanContent class="mb-16"></PlanContent>
      <NextStepPaln></NextStepPaln>
  </div>
</template>

<script>
import stepper from '../components/stepper.vue'
import plancontent from '../components/plancontent.vue'
import nextstepplan from '@/buttons/nextstep-plan.vue'
export default {
  components: {
    Stepper:stepper,
    PlanContent:plancontent,
    NextStepPaln:nextstepplan
  },
}
</script>

<style>

</style>